import React from "react";

import { Link } from "react-router-dom";
import cTwo from "../../assets/img/blog/blog1.jpg";
import pt from "../../assets/img/about-us/ab-us.jpg";
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";

import Slide from "./Sliders-two";
import Tabs from "./Tabs";
const Main = () => {
  return (
    <main>
      <div>
        {/* header area end here */}
        {/* page title area start */}
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + pt + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">FAQ Page</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">
                <Link to="/faq">FAQ Page</Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          {/* faq-area start */}
          <div className="ab-tabs pt-110 pb-120">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-10">
                  <div
                    className="section_title_wrapper text-center mb-50 wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <h2 className="section-title">
                      Frequently Asked Questions
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div
                  className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 wow fadeInUp"
                  data-wow-delay="0.3s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInUp",
                  }}
                >
                  <Tabs />
                </div>
                <div
                  className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 wow fadeInUp"
                  data-wow-delay="0.5s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="faqfrm__visa">
                    <div className="sidebar-title mb-40">
                      <h3>Ask Us Custom</h3>
                    </div>
                    <div className="faqfrm__visa-form">
                      <form action="#">
                        <input type="text" placeholder="Name*" />
                        <input type="email" placeholder="Email*" />
                        <input type="email" placeholder="Phone*" />
                        <select style={{ display: "none" }}>
                          <option> Subject</option>
                          <option>Visapass</option>
                          <option>Coaching</option>
                          <option>Services</option>
                        </select>
                        <div className="nice-select" tabIndex={0}>
                          <span className="current"> Subject</span>
                          <ul className="list">
                            <li
                              data-value="Subject"
                              className="option selected focus"
                            >
                              {" "}
                              Subject
                            </li>
                            <li
                              data-value="Choose an option"
                              className="option"
                            >
                              {" "}
                              Visapass
                            </li>
                            <li
                              data-value="Choose an option"
                              className="option"
                            >
                              {" "}
                              Coaching
                            </li>
                            <li
                              data-value="Choose an option"
                              className="option"
                            >
                              {" "}
                              Services
                            </li>
                          </ul>
                        </div>
                        <textarea cols={30} rows={10} defaultValue={""} />
                        <button className="theme-btn ">Submit Now</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* faq-area end */}
          {/* abbrand-area start */}
          <div
            className="abbrand-area pb-120 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="container">
              <Slide />
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
    </main>
  );
};

export default Main;
