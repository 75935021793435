import Accordion from 'react-bootstrap/Accordion';

function Clops() {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className="mb-3  border_color  shadow-sm ">
        <Accordion.Header className="font30 extraBold">Pre July 2021 critieria:</Accordion.Header>
        <Accordion.Body>
        <ul>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Nomination by a state or territory government </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Competent English</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Under 55 Years of age, unless a State or Territory Government nominates you as providing an “exceptional economic benefit” to
the state or territory </p>
                      </li>
                     
                    </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className="mb-3   shadow-sm ">
        <Accordion.Header className="font30 extraBold">The applicant will need to have an agreement for at least $200,000 in funding from one or more of the following sources:</Accordion.Header>
        <Accordion.Body>
        <ul>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Commonwealth agencies </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>State and territory governments</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Publicly funded research organisations</p>
                      </li>
                     
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Investors registered as Venture Capital Limited Partnerships or Early Stage Venture Capital Limited Partnerships</p>
                      </li>
                    </ul>
        </Accordion.Body>
      </Accordion.Item>
    
    </Accordion>
  );
}

export default Clops;