import React, { useState, useEffect } from "react";
import Sliders from "./Sliders";
import Slide from "./Sliders-two";

import Slidersthree from "./Sliders-three";
import heroOne from "../../assets/img/hero2/hero-1.jpg";
import heroTwo from "../../assets/img/hero2/hero-2.jpg";
import seal from "../../assets/img/about-2/seal.png";
import steps from "../../assets/img/step/steps-br.png";
import mapp from "../../assets/img/team/map-.png";

import partnersOne from "../../assets/img/partners/07.jpg";
import partnersTwo from "../../assets/img/country-img/c-1.jpg";

import services1 from "../../assets/img/services/services-1.jpg";
import services2 from "../../assets/img/services/services-2.jpg";
import services3 from "../../assets/img/services/services-3.jpg";
import services4 from "../../assets/img/services/services-4.jpg";

import pt from "../../assets/img/partners/pt-1.png";

const Main = () => {
  return (
    <main>
      {/* hero area start here */}

      <Sliders />

      {/* hero area end here */}
      {/* visa area start here */}
      <section className="visa-area ">
        <div className="container-fluid p-0">
          <div className="row g-0 theme-bg visa-top">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
              <div className="visa__items br-none">
                <div className="visa__items-single d-flex align-items-center">
                  <div className="visa__items-single-icon">
                    <i className="flaticon-passport-1" />
                  </div>
                  <h4 className="visa__items-single-title">
                    <a href=""> APPLY FOR YOUR COURSE</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
              <div className="visa__items">
                <div className="visa__items-single d-flex align-items-center">
                  <div className="visa__items-single-icon">
                    <i className="flaticon-customer" />
                  </div>
                  <h4 className="visa__items-single-title">
                    <a href=""> IMMIGRATE NOW​</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
              <div className="visa__items">
                <div className="visa__items-single d-flex align-items-center">
                  <div className="visa__items-single-icon">
                    <i className="flaticon-content" />
                  </div>
                  <h4 className="visa__items-single-title">
                    <a href=""> VISA INFORMATION​</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* visa area start here */}

      {/* features services end */}
      <section
        className="popular-country-2 pt-100 pb-80"
        style={{ backgroundImage: "url(" + partnersTwo + ")" }}
      >
        {/* <section
      className="popular-country-2 pt-5 pb-5"

    > */}
        <div className="container">
          <div className="row pb-10">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6">
              <div className="section_title_wrapper mb-30">
                {/* <span className="subtitle">Popular Country</span> */}
                <h2 className="section-title">FEATURED COUNTRIES</h2>
              </div>
            </div>
          </div>
          <div className="famous-countries">
            <Slide />
          </div>
        </div>
      </section>
      {/* Our Partners 2 start */}

      {/* Browse -Area start*/}
      <section className="browse-area pt-110 pb-90 grey-soft2-bg">
        <div className="container">
          <div
            className="row justify-content-center wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="col-xxl-10">
              <div className="section_title_wrapper text-center mb-50">
                <span className="subtitle">COURSES WE OFFER</span>
                <h2 className="section-title">Browse All the Courses</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp"
              data-wow-delay="0.3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="browse-box ">
                <div className="browse__wrapper">
                  <h4 className="browse__wrapper__title mb-25">
                    <a href="">Diploma Courses</a>
                  </h4>
                  <p className="browse__wrapper__subtitle mb-25">
                    Diploma Courses with the other family based employment based
                    and investment finally has got immigration with job.
                  </p>
                  <a className="browse-link read-more" href="">
                    View Courses <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp"
              data-wow-delay="0.4s"
              style={{
                visibility: "visible",
                animationDelay: "0.4s",
                animationName: "fadeInUp",
              }}
            >
              <div className="browse-box ">
                <div className="browse__wrapper">
                  <h4 className="browse__wrapper__title mb-25">
                    <a href="">Undergraduate Courses</a>
                  </h4>
                  <p className="browse__wrapper__subtitle mb-25">
                    Undergraduate program in higher education is designed for
                    early-career professionals seeking to make an impact{" "}
                  </p>
                  <a className="browse-link read-more" href="">
                    View Courses <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInUp",
              }}
            >
              <div className="browse-box ">
                <div className="browse__wrapper">
                  <h4 className="browse__wrapper__title mb-25">
                    <a href="">Graduate Courses</a>
                  </h4>
                  <p className="browse__wrapper__subtitle mb-25">
                    Graduate program in higher education is for early-career
                    professionals seeking to make an impact on the student
                    affairs track
                  </p>
                  <a className="browse-link read-more" href="">
                    View Courses <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp"
              data-wow-delay="0.6s"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "fadeInUp",
              }}
            >
              <div className="browse-box ">
                <div className="browse__wrapper">
                  <h4 className="browse__wrapper__title mb-25">
                    <a href="">Postgraduate Courses</a>
                  </h4>
                  <p className="browse__wrapper__subtitle mb-25">
                    Postgraduate program in higher education is for early-career
                    professionals seeking to make an impact on the student
                  </p>
                  <a className="browse-link read-more" href="">
                    View Courses <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp"
              data-wow-delay="0.7s"
              style={{
                visibility: "visible",
                animationDelay: "0.7s",
                animationName: "fadeInUp",
              }}
            >
              <div className="browse-box ">
                <div className="browse__wrapper">
                  <h4 className="browse__wrapper__title mb-25">
                    <a href="">English Courses</a>
                  </h4>
                  <p className="browse__wrapper__subtitle mb-25">
                    Research program in higher education is for early-career
                    professionals seeking to make an impact on the student{" "}
                  </p>
                  <a className="browse-link read-more" href="">
                    View Courses <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp"
              data-wow-delay="0.8s"
              style={{
                visibility: "visible",
                animationDelay: "0.8s",
                animationName: "fadeInUp",
              }}
            >
              <div className="browse-box ">
                <div className="browse__wrapper">
                  <h4 className="browse__wrapper__title mb-25">
                    <a href="">PHD Courses</a>
                  </h4>
                  <p className="browse__wrapper__subtitle mb-25">
                    PHD program in higher education is designed for early-career
                    professionals seeking to make an impact on the student
                    affairs track
                  </p>
                  <a className="browse-link read-more" href="">
                    View Courses <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Browse -Area end*/}

      {/* Blog 2 start */}
      <section
        className="blog2-area- pt-110 pb-90 wow fadeInUp"
        data-wow-delay="0.3s"
        style={{
          visibility: "visible",
          animationDelay: "0.3s",
          animationName: "fadeInUp",
        }}
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="section_title_wrapper mb-50">
                <span className="subtitle">Our</span>
                <h2 className="section-title">
                  Visa &
                  <br /> Immigration Services
                </h2>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-30">
              <p>
                We provide assistance with various kind of visas like tourist or
                visit visa, business visa, student visa and employment or work
                visa. Besides we also assist for permanent residency options for
                countries like Canada and Australia as well as for business
                immigration to countries like Portugal, Greece, Spain, Malta,
                Belgium, France, United Kingdom, Turkey along with USA, Canada
                and Australia.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <article className="blog mb-30">
                <div className="blog__thumb">
                  <a href="/business-visa">
                    <img src={services1} alt="" />
                  </a>
                </div>
                <div className="blog__content">
                  <div className="blog-text">
                    <h3 className="blog__content__title">
                      <a href="/business-visa">Business Visa</a>
                    </h3>
                    <p>
                      Business visa is required by those who generally visit a
                      foreign country for conference...
                    </p>
                    <div className="read-more">
                      <a href="/business-visa">
                        Read More <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <article className="blog mb-30">
                <div className="blog__thumb">
                  <a href="/student-visa">
                    <img src={services3} alt="" />
                  </a>
                </div>
                <div className="blog__content">
                  <div className="blog-text">
                    <h3 className="blog__content__title">
                      <a href="/student-visa">Student Visa</a>
                    </h3>
                    <p>
                      Any student who wants to study in a foreign country would
                      require a student visa…
                    </p>
                    <div className="read-more">
                      <a href="/student-visa">
                        Read More <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <article className="blog mb-30">
                <div className="blog__thumb">
                  <a href="/work-job-visa">
                    <img src={services4} alt="" />
                  </a>
                </div>
                <div className="blog__content">
                  <div className="blog-text">
                    <h3 className="blog__content__title">
                      <a href="/work-job-visa">Work & Job Visa</a>
                    </h3>
                    <p>
                      All nationals who intend to work outside their home
                      country need a work visa…
                    </p>
                    <div className="read-more">
                      <a href="/work-job-visa">
                        Read More <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <article className="blog mb-30">
                <div className="blog__thumb">
                  <a href="/tourist-visitor-visa">
                    <img src={services2} alt="" />
                  </a>
                </div>
                <div className="blog__content">
                  <div className="blog-text">
                    <h3 className="blog__content__title">
                      <a href="/tourist-visitor-visa">Tourist & Visitor Visa</a>
                    </h3>
                    <p>
                      A visitor visa or tourist visa is is an official document
                      that is struck in your passport…
                    </p>
                    <div className="read-more">
                      <a href="/tourist-visitor-visa">
                        Read More <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      {/* Blog 2 end */}

      {/* features services start */}
      <section className="call-to-action3 d-flex align-items-center">
        <div className="container">
          <div className="row theme-bg  g-0 pad-cut pt-40 pb-40 pr-80 pl-80 mb-80">
            <div className="col-xxl-7 col-xl-7 col-lg-7">
              <div className="call3-left">
                <h2>FOR A FREE ASSESSMENT</h2>
                <p>EMAIL US AT INFO@TRAVELKOOK.COM</p>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 cbr-left d-flex align-items-center justify-content-end">
              <div className="calltoaction3__wrapper d-flex align-items-center">
                <div className="calltoaction3__wrapper__icon mr-10">
                  <i className="fal fa-headset" />
                </div>
                <div className="calltoaction3__wrapper__call-number">
                  <span>Call for help</span>
                  <h5>
                    <a href="tel:+1878298023">+965-98726180</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Partners 2 end */}
      {/* Testimonial 2 start */}
      {/* <div
        className="testimonial-2 pt-110 pb-135 d-flex align-items-center"
        style={{ backgroundImage: "url(" + partnersOne + ")" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10">
              <div className="section_title_wrapper text-center mb-20">
                <span className="subtitle">Testimonials</span>
                <h2 className="section-title">
                  What Clients Say About Us and <br /> Our Services
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <Slidersthree />
            <div className="testimonail2-active  owl-carousel text-center testi-pad">
              <div className="testimonail__wrapper testimonail__wrapper2">
                <div className="testimonail__header">
                  <div className="testimonail__header__img mb-25">
                    <img
                      src="assets/img/testimonial/tauthor-1.png"
                      style={{ width: "60px" }}
                      alt=""
                    />
                  </div>
                  <div className="testimonail__header__content mb-35">
                    <h4>Karlosh Tremon</h4>
                    <p>Student</p>
                  </div>
                </div>
                <div className="testimonail__body mb-35">
                  <p>
                    Travellers from countries categorized under the high-risk
                    list who are eligible to enter Germany, aged 12 and older,
                    are obliged to present their vaccination certificates. There
                    are many variations of passages of Lorem Ipsum available,
                    but the majority have suffered
                  </p>
                </div>
                <div className="testimonail__footer">
                  <ul>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>(Switzerland Visa)</li>
                  </ul>
                </div>
              </div>
              <div className="testimonail__wrapper testimonail__wrapper2">
                <div className="testimonail__header">
                  <div className="testimonail__header__img mb-25">
                    <img src="assets/img/testimonial/tauthor-1.png" alt="" />
                  </div>
                  <div className="testimonail__header__content mb-35">
                    <h4>Daniel Hasmass</h4>
                    <p>Manager</p>
                  </div>
                </div>
                <div className="testimonail__body mb-35">
                  <p>
                    Travellers from countries categorized under the high-risk
                    list who are eligible to enter Germany, aged 12 and older,
                    are obliged to present their vaccination certificates. There
                    are many variations of passages of Lorem Ipsum available,
                    but the majority have suffered
                  </p>
                </div>
                <div className="testimonail__footer">
                  <ul>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>(Switzerland Visa)</li>
                  </ul>
                </div>
              </div>
              <div className="testimonail__wrapper testimonail__wrapper2">
                <div className="testimonail__header">
                  <div className="testimonail__header__img mb-25">
                    <img src="assets/img/testimonial/tauthor-1.png" alt="" />
                  </div>
                  <div className="testimonail__header__content mb-35">
                    <h4>Narayan Kamora</h4>
                    <p>Senior Lawyer</p>
                  </div>
                </div>
                <div className="testimonail__body mb-35">
                  <p>
                    Travellers from countries categorized under the high-risk
                    list who are eligible to enter Germany, aged 12 and older,
                    are obliged to present their vaccination certificates. There
                    are many variations of passages of Lorem Ipsum available,
                    but the majority have suffered
                  </p>
                </div>
                <div className="testimonail__footer">
                  <ul>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>(Switzerland Visa)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Testimonial 2 end */}
    </main>
  );
};

export default Main;
