import React from "react";

import { Link } from "react-router-dom";
import cTwo from "../../assets/img/blog/blog1.jpg";
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";
import Tabs from "./Tabs";

import cfou from "../../assets/img/country-img/Germany.jpg";
import img_one from "../../assets/img/country-img/img_one.jpg";
import img_for from "../../assets/img/country-img/img_for.jpg";
import imge_two from "../../assets/img/country-img/imge_two.jpg";
import img_five from "../../assets/img/country-img/img_five.webp";

import pt from "../../assets/img/country-img/bannercountries.jpg";
import Slide from "./Sliders-two";
const Main = () => {
  return (
    <main>
      <div>
        {/* header area end here */}
        {/* page title area start */}
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + pt + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">Countries</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">
                {" "}
                <Link to="/countries">Countries</Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          <div className="most-countries pt-100 pb-90">
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="section_title_wrapper mb-25">
                    <h2
                      className="section-title mb-5"
                      style={{ fontSize: "40px" }}
                    >
                      PARTNER OFFICES WORLDWIDE
                    </h2>
                  </div>
                </div>
                {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-30 dflx-content-start d-flex justify-content-end">
                  <select className="contact__input mostpppr-niceselect">
                    <option value>All Countries</option>
                    <option value>United States</option>
                    <option value>New Zealand</option>
                    <option value>United Kingdom</option>
                    <option value>Switzerland</option>
                    <option value>Australia </option>
                  </select>
                </div> */}
              </div>
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                        <img src={cfou} alt="" style={{ height: "180px" }} />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html">
                            <i className="fal fa-plus" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      {/* <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/Germany-1.png" alt="" />
                      </div> */}
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">GERMANY OFFICE:</a>
                        </h5>
                        <p>MUNICH, GERMANY</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                        <img src={img_one} alt="" style={{ height: "180px" }} />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html">
                            <i className="fal fa-plus" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      {/* <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag2.png" alt="" />
                      </div> */}
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">AUSTRALIA OFFICE:</a>
                        </h5>
                        <p>SYDNEY, AUSTRALIA</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                        <img src={img_for} alt="" style={{ height: "180px" }} />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html">
                            <i className="fal fa-plus" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      {/* <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag3.png" alt="" />
                      </div> */}
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">POLAND OFFICE:</a>
                        </h5>
                        <p>WARSAW, POLAND</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                        <img
                          src={imge_two}
                          alt=""
                          style={{ height: "180px" }}
                        />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html">
                            <i className="fal fa-plus" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      {/* <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag4.png" alt="" />
                      </div> */}
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">USA OFFICE:</a>
                        </h5>
                        <p>FLORIDA, USA</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                        <img
                          src={img_five}
                          alt=""
                          style={{ height: "180px" }}
                        />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html">
                            <i className="fal fa-plus" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      {/* <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag5.png" alt="" />
                      </div> */}
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">CANADA OFFICE: </a>
                        </h5>
                        <p>CALGARY, CANADA</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" style={{height: '180px'}}/>
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag6.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Bangladesh</a>
                        </h5> 
                        <p>25 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" style={{height: '180px'}}/>
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag7.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Canada</a>
                        </h5> 
                        <p>33 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" style={{height: '180px'}}/>
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag8.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">China</a>
                        </h5> 
                        <p>38 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" style={{height: '180px'}}/>
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag9.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Denmark</a>
                        </h5> 
                        <p>33 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" style={{height: '180px'}}/>
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag10.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">France</a>
                        </h5> 
                        <p>25 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" style={{height: '180px'}}/>
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag11.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Germany</a>
                        </h5> 
                        <p>33 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" style={{height: '180px'}}/>
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag12.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Norway</a>
                        </h5> 
                        <p>38 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div> */}
              </div>
            </div>
          </div>
          {/* abbrand-area start */}
          <div
            className="abbrand-area pb-120 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="container">
              <div className="section_title_wrapper mb-25">
                <h2 className="section-title mb-4" style={{ fontSize: "25px" }}>
                  WE HAVE PARTNER OFFICES IN OTHER COUNTRIES
                </h2>
              </div>
              <Slide />
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
    </main>
  );
};

export default Main;
