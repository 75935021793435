import React from "react";

import { Link } from "react-router-dom";
import visabnr from "../../../assets/img/visa/v-1.jpg";

const Main = () => {
  return (
    <main>
      <div>
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + visabnr + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">Business Visa</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">Business Visa</li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <section
          className="blog-area pt-60 pb-30 wow fadeInUp"
          data-wow-delay="0.3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
        >
          <div className="container">
            <div className="row">
              <p>
                Business visa is required by those who generally visit a foreign
                country for conference or for meeting for business
                opportunities. Travelkook assists in the application process of
                business visa for various countries provided by invitation
                letter is arranged by the client . The tenure of the business
                visa depends upon the invitation letter issued by the company,
                who is inviting the client to it's country of operation for
                business person.
              </p>
              <p>
                In case you want us to arrange the business invitation for you,
                we can only provide invitations for the following countries
                provided your purpose of visit is for business only;
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">SPAIN</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">POLAND</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">GERMANY</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">BELGIUM</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">CANADA</p>
                </li>
              </ul>
              <p className="pt-15 pb-10">
                General Documents required for Business Visa :
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Passport</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    National ID Card or Residency Card if applying from any
                    other country apart from the Home Country
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Bank Statements (3 to 6 months depending upon the
                    application country)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Travel Insurance</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Salary Certificate</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Leave Certificate or NOC</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Company License (in case of Business Personals)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Partnership Document (in case of Business Personals)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Invitation Letter (from the inviting company)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Financial and accommodation proof of the inviting company in
                    case the company is sponsoring all the client’s cost.{" "}
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Proof of accommodation (generally provided by us)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Air ticket Booking (generally provided by us)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Travel Itinerary (generally provided by us)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Application Form (generally provided by us)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Photo (specification varies from country to country)
                  </p>
                </li>
              </ul>
              <p className="pt-15 pb-15">
                Apart from the countries who allow to issue evisas, candidates
                have to visit the embassies or visa application centers for
                giving biometrics and for applying for their visas in person.
                The appointment would be provided by us.
              </p>
            </div>
          </div>
        </section>

        {/* JS here */}
      </div>
    </main>
  );
};

export default Main;
