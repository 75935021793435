import React from "react";

import { Link } from "react-router-dom";
import cTwo from "../../assets/img/blog/blog1.jpg";
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";
import Tabs from "./Tabs";

import cfou from "../../assets/img/country-img/Germany.jpg";
import img_one from "../../assets/img/country-img/img_one.jpg";
import img_for from "../../assets/img/country-img/img_for.jpg";
import two from "../../assets/img/country-img/poland.jpg";
import img_five from "../../assets/img/country-img/img_three.jpg";

import aus from "../../assets/img/country-img/poland.png";
import Slide from "./Sliders-two";
const Main = () => {
  return (
    <main>
      <div>
        {/* page title area start */}
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + two + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">STUDY IN POLAND</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">
                {" "}
                <Link to="/">Poland</Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          <div className="united-stats-area pt-120">
            <div className="container">
              <div className="row justify-content-center">
                {/* <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <div className="sidebar-left__wrapper">
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="cat-link">
                          <ul>
                            <li><a className="vrbg" href="united-states.html">Australia</a></li>
                            <li><a href="united-states.html">Bangladesh</a></li>
                            <li><a href="united-states.html">Switzerland</a></li>
                            <li><a href="united-states.html">Norway</a></li>
                            <li><a href="united-states.html">Germany</a></li>
                            <li><a href="united-states.html">United Kingdom</a></li>
                            <li><a href="united-states.html">France</a></li>
                            <li><a href="united-states.html">South Africa</a></li>
                            <li><a href="united-states.html">Canada</a></li>
                            <li><a href="united-states.html">Denmark</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30 theme-bg pd-30">
                      <div className="sidebar__widget-title title-white mb-40">
                        <h4>Download</h4>
                      </div>
                      <div className="sidebar__widget-content">
                        <ul>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Service Broohoru</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Visa Application Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border pdf-btm-none">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Admission Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="faqfrm__visa mb-30">
                      <div className="sidebar-title mb-40">
                        <h3>Ask Us Custom</h3>
                      </div>
                      <div className="faqfrm__visa-form">
                        <form action="#">
                          <input type="text" placeholder="Name*" />
                          <input type="email" placeholder="Email*" />
                          <input type="email" placeholder="Phone*" />
                          <select style={{display: 'none'}}>
                            <option> Subject</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                          </select><div className="nice-select" tabIndex={0}><span className="current"> Subject</span><ul className="list"><li data-value="Subject" className="option selected focus"> Subject</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li></ul></div>
                          <textarea cols={30} rows={10} defaultValue={""} />
                          <button className="theme-btn ">Submit Now</button>
                        </form>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="sidebar__widget-content-banner">
                          <img src={img_five} alt="" />
                          <div className="sidebar__widget-content-banner-text">
                            <span>Higher Study</span>
                            <h2>In Austalia</h2>
                            <a href="countries.html" className="banner-btn">Apply Visa</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-xxl-10 col-xl-10 col-lg-10">
                  <div className="sidebar__deatils">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={img_five}
                          alt=""
                          style={{ width: "100%", height: "350px" }}
                        />
                      </div>
                      <h3 className="united-states__title mb-15">ABOUT US:</h3>
                      <p className="mb-30">
                        Our aim is to help students from across the world find
                        their perfect course in universities in Poland. We
                        partner with over 200 leading universities, schools, and
                        institutes across various cities in Poland, including
                        Warsaw, Poznań, Bydgoszcz and Toruń, Kraków, Łódź,
                        Wrocław, Lublin, Gorzów Wielkopolski and Zielona Góra,
                        Opole, Białystok, Gdańsk, Katowice, Rzeszów, Kielce,
                        Olsztyn, Szczecin.
                      </p>
                      {/* <p className="mb-25">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                      </p>  */}
                    </div>
                    <div className="united-information pb-30">
                      <div className="row align-items-center">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                          <div className="united-info">
                            <h4
                              className="united-states__title2 mb-25"
                              style={{ fontSize: "19px" }}
                            >
                              We have helped thousands of students get their
                              dream courses.
                            </h4>
                            <ul>
                              <li style={{ marginLeft: "15px" }}>
                                <p>
                                  • We have the experience of studying abroad{" "}
                                  <br />
                                  • We offer you the best services <br />•
                                  Comfortable stay, full-time assistance <br />
                                  • Connecting and networking globally <br />
                                  • We assure suitable prices <br />
                                  • Balancing travel expenditures <br />
                                  • Facilitation of Visa <br />
                                  • 24x7 Support Values v Learning v Integrity v
                                  Fast v Empathy <br />
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                          <div className="united-thumb">
                            <img src={aus} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title2 mb-25">
                      OUR SERVICES:
                    </h3>
                    <ul>
                      {" "}
                      <li style={{ marginLeft: "15px" }}>
                        <p className="mb-15">
                          Ø CHOOSING THE RIGHT COURSE <br />
                          Ø UNIVERSITY APPLICATION ON STUDENT BEHALF <br />
                          Ø ADMISSION GUARANTEE <br />
                          Ø PREPARATORY LANGUAGE COURSES <br />
                          Ø STUDENT VISA APPLICATION ASSISTANCE <br />
                          Ø STATEMENT OF PURPOSE <br />
                          Ø JOB SEARCH <br />
                          Ø RESIDENT CARD <br />
                          Ø OPEN BANK ACCOUNT IN POLAND FOR NON RESIDENT <br />
                        </p>
                      </li>
                    </ul>
                    {/* <h3 className="united-states__title3 mb-25">
                      Life Styles
                    </h3>
                    <p className="mb-35">An applicant should apply at center in or closest to, the city in which you are documented as being a resident. Please contact VFS Global if you have any doubts. This crossword explores the various paths humankind can follow to create a more sustainable world. Find out how many ways we can change our approach to the environment
                    </p>
                    <h3 className="united-states__title3 mb-20">
                      Law &amp; Rules
                    </h3>
                    <p className="mb-35">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                    </p> */}
                    <div className="beautiful-cities mb-10">
                      <div className="row">
                        <div className="col-xxl-10 col-xl-10 col-lg-10">
                          <div className="beautiful-cites">
                            <h3 className="united-states__title3 mb-15">
                              COURSES OFFERED:
                            </h3>

                            <div className="beautiful-link mb-30">
                              <ul>
                                <li>
                                  <a className="active" href="#0">
                                    {" "}
                                    Ø BACHELORS
                                  </a>
                                </li>
                                <li>
                                  <a href="#0">Ø MASTERS </a>
                                </li>
                                <li>
                                  <a href="#0">Ø DIPLOMAS </a>
                                </li>
                                <li>
                                  <a href="#0">Ø POLISH LANGUAGE COURSES </a>
                                </li>
                                <li>
                                  <a href="#0">Ø PHD </a>
                                </li>

                                {/* <li><a href="#0">California, </a></li>
                                <li><a href="#0">Florida, </a></li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                          <div className="beautiful-cites-place">
                            <img
                              src="assets/img/united-states/butifull.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title3 mb-20">
                      POPULAR COURSES :
                    </h3>
                    <ul>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Agriculture and Forestry </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Applied Sciences and Professions </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Arts, Design and Architecture </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Business & Management </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Computer & IT </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Education & Training </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Engineering & Technology </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Natural Sciences and Mathematics </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Environmental Science and Earth Science </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Hospitality Leisure and Sports </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Humanities </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Language & Culture </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Law </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Journalism & Media </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Medicine & Health </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Social Sciences </p>
                      </li>
                    </ul>

                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">
                        WHY POLAND:
                      </h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ marginLeft: "15px" }}>
                            <p>
                              Ø POLAND STAND 5TH AMONG THE BEST PLACES FOR
                              STUDYING IN EUROPE <br />
                              Ø HOME TO SEVERAL OF THE BEST UNIVERSITIES IN
                              EUROPE IN DIVERSE FIELD
                              <br />
                              Ø CONTAINS 11 OF THE BEST UNIVERSITIES IN THE
                              WORLD IN TERMS OF STUDENT SATISFACTION <br />
                              Ø AFFORDABILITY <br />
                              Ø LOW COST LIVING WITH GOOD QUALITY LIFE <br />
                              Ø SCHOLARSHIP PROGRAM <br />
                              Ø IELTS OR ENGLISH LANGUAGE REQUIREMENT IS NOT
                              REQUIRED IN MOST CASES <br />
                              Ø EASY RESIDENCY PROCEDURE <br />
                              Ø RICH IN CULTURE <br />
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">
                        DOCUMENTS REQUIRED FOR ENROLLMENT:
                      </h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ marginLeft: "15px" }}>
                            <p>
                              Ø RESUME / CV
                              <br />
                              Ø DEGREE CERTIFICATES <br />
                              Ø TRANSCRIPTS
                              <br />
                              Ø ATLEAST 1 YEAR WORK EXPERIENCE FOR SPECIFIC
                              MASTER DEGREE <br />
                              Ø DIPLOMAS IF ANY <br />
                              Ø MEDIUM OF INSTRUCTION CERTIFICATE FOR PROOF OF
                              ENGLISH <br />
                              Ø IELTS / TOEFL / DOULINGO OPTIONAL <br />
                              Ø STATEMENT OF PURPOSE <br />
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">FACTS :</h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Tuition Fees varying between 20,000 and 45,000
                              Australian dollars per year
                            </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p> Located in central Europe </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Area: 312,685 sq km </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p> Population: around 38.4 million</p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p> Capital: Warsaw (also the largest city) </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              Borders with Belarus, Czech Republic, Germany,
                              Lithuania, Russia, Slovakia, Ukraine and the
                              Baltic sea{" "}
                            </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              Students will need a minimum of 30,000 PLN
                              (~US$7,820) per year to cover their living costs
                              in Poland.{" "}
                            </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              International tuition fees can reach up to
                              ~US$4,180 per year at public universities.{" "}
                            </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              Poland has a TV channel dedicated to the Pope.{" "}
                            </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p> National currency: zloty (PLN) </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              More winners of the “World’s Strongest Man”
                              competition than any other location{" "}
                            </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p> 17 Nobel Prize winners </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              9,300 lakes, 23 National Parks and one desert{" "}
                            </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              90 percent of Poles have completed at least
                              secondary education, the highest score in the EU,
                              along with Czechs, Slovaks and Slovenes.
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              Polish-born astronomer Nicolaus Copernicus was the
                              first person to suggest that the Earth was in fact
                              not the centre of the universe.{" "}
                            </p>
                          </li>{" "}
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              70 percent of the Nazi extermination camps during
                              WWII were in Poland, including the three most
                              infamous, Auschwitz, Treblinka and Belzec STUDY IN
                              USA World’s largest population of International
                              Students Career & Research Opportunities.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area start */}
          <div
            className="abbrand-area pt-120 pb-120 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="container">
              <div className="abbrand-active owl-carousel">
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/1.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/2.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/3.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/5.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
    </main>
  );
};

export default Main;
