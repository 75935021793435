import React from "react";

import { Link } from "react-router-dom";
import visabnr from "../../../assets/img/visa/v-1.jpg";

const Main = () => {
  return (
    <main>
      <div>
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + visabnr + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">Tourist & Visitor Visa</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">Tourist & Visitor Visa</li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <section
          className="blog-area pt-60 pb-30 wow fadeInUp"
          data-wow-delay="0.3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
        >
          <div className="container">
            <div className="row">
              <p>
                A visitor visa or tourist visa is is an official document that
                is struck in your passport and it shows that you meet the
                requirements needed to enter a foreign country. The visa is
                generally issued for a certain time limit depending upon the
                visa application. People can stay upto 6 months at one shot with
                visit visa for majority of the countries.
              </p>

              <p className="pt-15 pb-0">
                Travelkook is expertise in providing visa assistance for the
                purpose of tourism or visit. Whether you want to visit a country
                or would like to visit your relatives, family or friends staying
                there, Travelkook will guide you on each and every step of your
                visa application.
              </p>

              <p className="pt-15 pb-0">
                We provide visa assistance for the following countries:
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">SCHENGEN (all European countries)</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">USA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">CANADA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">AUSTRALIA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">UNITED KINGDOM</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">UAE</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">RUSSIA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">IRELAND</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">SINGAPORE</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">THAILAND</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">MALAYSIA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">SOUTH AFRICA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">JAPAN</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">SOUTH KOREA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">CAMBODIA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">VIETNAM</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">NEWZEALAND</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">MEXICO</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">BRAZIL</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">ARGENTINA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">CHILE</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">TANZANIA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">RAWANDA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">KENYA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">ZIMBABWE</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">NAMIBIA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">MOROCCO</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">EGYPT</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">QATAR</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">OMAN</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">LEBANON</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">JORDAN</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">BAHRAIN</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">TURKEY</p>
                </li>
              </ul>
              <p className="pt-15 pb-0">
                General Documents required for Visit Visa:
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Passport</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    National ID Card or Residency Card if applying from any
                    other country apart from the Home Country
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Bank Statements (3 to 6 months depending upon the
                    application country)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Travel Insurance</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Salary Certificate</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Leave Certificate or NOC</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Company License (in case of Business Personals)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Partnership Document (in case of Business Personals)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Invitation Letter (in case of visiting relatives, letter
                    from the person who is inviting you )
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Inviting Person’s financial statement, salary certificate
                    and residency proof (in case of visiting relatives)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Proof of accommodation (generally provided by us)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Air ticket Booking (generally provided by us)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Travel Itinerary (generally provided by us)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Application Form (generally provided by us)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Photo (specification varies from country to country)
                  </p>
                </li>{" "}
              </ul>
              <p className="pt-15 pb-0">
                Apart from the countries who allow to issue e-visas, candidates
                have to visit the embassies or visa application centers for
                giving biometrics and for applying for their visas in person.
                The appointment would be provided by us.
              </p>
            </div>
          </div>
        </section>

        {/* JS here */}
      </div>
    </main>
  );
};

export default Main;
