import React from "react";

import { Link } from "react-router-dom";

import about from "../../assets/img/about-us/ab-us.jpg";

import abouttwo from "../../assets/img/about-us/ab-m.jpg";

import sd from "../../assets/img/team/sd.png";
import a from "../../assets/img/team/a.png";
import b from "../../assets/img/team/b.png";
import p from "../../assets/img/team/p.png";
import v from "../../assets/img/team/v.jpg";

import SliderOne from "./Sliders-three";
import Slide from "./Sliders-two";
import Tabs from "./Tabs";
const Main = () => {
  return (
    <div>
      {/* header area end here */}
      {/* page title area start */}
      <div
        className="page-title__area pt-110"
        style={{ backgroundImage: "url(" + about + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                <h3 className="pb-100">About Us </h3>
              </div>
            </div>
          </div>
        </div>
        <nav className="breadccrumb-bg">
          <ul className="breadcrumb justify-content-center pt-20 pb-20">
            <li className="bd-items">
              <Link to="/">Home</Link>
            </li>
            <li className="bd-items bdritems">|</li>
            <li className="bd-items">
              {" "}
              <Link to="/about"> About Us</Link>
            </li>
          </ul>
        </nav>
      </div>
      {/* page title area end */}
      <main>
        {/* About-us area start here */}
        <section className="about-area-2 pt-120 pb-80">
          <div className="container">
            <div className="row">
              <div
                className="col-xxl-5 col-xl-5 col-lg-5 mb-30 wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="about2-left d-flex">
                  <div className="about2-left__img1 mr-10">
                    <img src={abouttwo} alt="" />
                  </div>
                  {/* <div className="about2-left__img2">
                    <img src={aboutwo} alt="" />
                    <div className="about2-left__info d-flex align-items-center">
                      <div className="about2-left__info__left mr-15">
                        <img src={seal} seal alt="" />
                      </div>
                      <div className="about2-left__info__right">
                        <h4>ISO Certified</h4>
                        <p>1990-2000</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div
                className="col-xxl-7 col-xl-7 col-lg-7 mb-30 wow fadeInUp"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="section_title_wrapper">
                  <span className="subtitle">About Us</span>
                  <h2 className="section-title about-span mb-30">
                    <span>Travelkook </span> founded in 2019
                  </h2>
                  <div className="section_title_wrapper__about-content mb-20">
                    <p>
                      Started its journey as travel consultancy and gradually
                      expanded themselves in the field of immigration and
                      education. Travelkook now is partnered with over 241
                      universities all around the world and have partner offices
                      in over 12 countries of the world. Our aim is to provide
                      the best solution to the client in the most affordable
                      way.
                    </p>
                    <p>
                      Education Consulting is one of our key services and we
                      provide complete packages to the students starting from
                    </p>
                  </div>
                </div>
                <div className="about-trust">
                  <div className="row mb-10">
                    <div className="col-lg-12">
                      <div className="about2__item d-flex  mb-20">
                        <ul className="about2__icon">
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Counseling the Student and analyzing the student's
                            profile
                          </li>
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Suggesting suitable universities to the students
                            depending upon the student profile analysis
                          </li>
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Shortlisting the Universities for the student for
                            admission
                          </li>
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Step by step assistance with the entire admission
                            procedure
                          </li>
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Guiding in document preparation for the entire
                            admission procedure
                          </li>
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Guidance with State of Purpose and other essay
                            writing
                          </li>
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Acquiring the Offer Letter from the University
                          </li>
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Visa Assistance
                          </li>
                          <li className="about2__content">
                            <i className="bdevs-btn-icon far fa-check-square" />
                            Landing support after Student reaches the study
                            destination
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-xxl-12 col-xl-12 col-lg-12 mb-30 wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="section_title_wrapper__about-content mb-20">
                  <p>
                    So, if you are looking for a complete educational consulting
                    package, Travelkook is your one stop solution for it.
                  </p>
                  <p>
                    Immigration is another integral part of our services. We
                    provide assistance in processing Permanent Residency for
                    Canada and Australia through different schemes. We also
                    provide Sponsorship programs for Australia under subclass
                    186, that is direct entry to Australia.
                  </p>
                  <p>
                    If you are looking to open up your new business abroad or
                    buy a property or open a branch office then you are in the
                    right place. Travelkook offers business immigration programs
                    to the following countries:
                  </p>
                  <div className="row">
                    <div className="country__list col-xxl-2 col-xl-2 col-lg-2 flex mb-20">
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        1) USA
                      </div>
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        7) Greece
                      </div>
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        13) United Kingdom
                      </div>
                    </div>

                    <div className="country__list col-xxl-2 col-xl-2 col-lg-2 flex mb-20">
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        2) Canada
                      </div>
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        8) France
                      </div>
                    </div>

                    <div className="country__list col-xxl-2 col-xl-2 col-lg-2 flex mb-20">
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        3) Australia
                      </div>
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        9) Belgium
                      </div>
                    </div>

                    <div className="country__list col-xxl-2 col-xl-2 col-lg-2 flex mb-20">
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        4) Poland
                      </div>
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        10) Malta
                      </div>
                    </div>

                    <div className="country__list col-xxl-2 col-xl-2 col-lg-2 flex mb-20">
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        5) Portugal
                      </div>
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        11) Hungary
                      </div>
                    </div>

                    <div className="country__list col-xxl-2 col-xl-2 col-lg-2 flex mb-20">
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        6) Spain
                      </div>
                      <div
                        className="mb-20 wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}
                      >
                        12) Turkey
                      </div>
                    </div>
                  </div>
                  <p>
                    We will guide you through the entire process be it searching
                    of property, or opening up a new business or starting a
                    representative office or branch office of your company . All
                    our business immigration processes are handled by our
                    lawyers and you can process the whole procedure from your
                    home country only.
                  </p>
                  <p>
                    For further details please contact us at{" "}
                    <span>+965-98726180</span> or email us at{" "}
                    <span>
                      <a href="mailto:sanghita.deb@travelkook.com">
                        sanghita.deb@travelkook.com
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About-us area end here */}

        {/* Team area start */}
        <section
          className="team-area grey-soft-bg pt-110 pb-80"
          style={{
            backgroundImage: "url(assets/img/testimonial/tsti1-bg.jpg)",
          }}
        >
          <div className="container">
            <div
              className="row justify-content-center wow fadeInUp"
              data-wow-delay="0.3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="col-xxl-10">
                <div className="section_title_wrapper text-center mb-50">
                  <span className="subtitle">Our Dedicated</span>
                  <h2 className="section-title">TEAM AND PARTNERS</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12"></div>
              <div
                className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="team text-center mb-30">
                  <div className="team__thumb team__thumb-2 mb-25">
                    <img src={sd} alt="" />
                  </div>
                  <div className="team__text">
                    <h3 className="team__text-title">
                      <a href="team-details.html">MISS SANGHITA</a>
                    </h3>
                    <span>Owner and Founder, Travelkook</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="team text-center mb-30">
                  <div className="team__thumb team__thumb-2 mb-25">
                    <img src={b} alt="" />
                  </div>
                  <div className="team__text">
                    <h3 className="team__text-title">
                      <a href="team-details.html">MR. BODHISATWYA</a>
                    </h3>
                    <span>Country Head Travelkook, India</span>
                  </div>
                </div>
              </div>
              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="team text-center mb-30">
                  <div className="team__thumb team__thumb-2 mb-25">
                    <img src={v} alt="" />
                  </div>
                  <div className="team__text">
                    <h3 className="team__text-title">
                      <a href="team-details.html">MRS. VALENTYNA</a>
                    </h3>
                    <span>
                      Recruitment Specialist, Travelkook Partner Poland
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="team text-center mb-30">
                  <div className="team__thumb team__thumb-2 mb-25">
                    <img src={p} alt="" />
                  </div>
                  <div className="team__text">
                    <h3 className="team__text-title">
                      <a href="team-details.html">MR. PETER</a>
                    </h3>
                    <span>Lawyer, Travelkook Partner Germany</span>
                  </div>
                </div>
              </div>
              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="team text-center mb-30">
                  <div className="team__thumb team__thumb-2 mb-25">
                    <img src={a} alt="" />
                  </div>
                  <div className="team__text">
                    <h3 className="team__text-title">
                      <a href="team-details.html">MR. ARUP</a>
                    </h3>
                    <span>Australian Consultant and Partner, Travelkook</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Team area end */}
      </main>
    </div>
  );
};

export default Main;
