import React from "react";

import { Link } from "react-router-dom";
import cTwo from "../../assets/img/blog/blog1.jpg";
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";
import Tabs from "./Tabs";

import two from "../../assets/img/country-img/g.jpg";
import img_five from "../../assets/img/country-img/Germany.jpg";

import aus from "../../assets/img/country-img/germany.png";
import Slide from "./Sliders-two";
const Main = () => {
  return (
    <main>
      <div>
        {/* page title area start */}
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + two + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">STUDY IN GERMANY</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">
                {" "}
                <Link to="/">GERMANY</Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          <div className="united-stats-area pt-120">
            <div className="container">
              <div className="row justify-content-center">
                {/* <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <div className="sidebar-left__wrapper">
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="cat-link">
                          <ul>
                            <li><a className="vrbg" href="united-states.html">GERMANY</a></li>
                            <li><a href="united-states.html">Bangladesh</a></li>
                            <li><a href="united-states.html">Switzerland</a></li>
                            <li><a href="united-states.html">Norway</a></li>
                            <li><a href="united-states.html">Germany</a></li>
                            <li><a href="united-states.html">United Kingdom</a></li>
                            <li><a href="united-states.html">France</a></li>
                            <li><a href="united-states.html">South Africa</a></li>
                            <li><a href="united-states.html">Canada</a></li>
                            <li><a href="united-states.html">Denmark</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30 theme-bg pd-30">
                      <div className="sidebar__widget-title title-white mb-40">
                        <h4>Download</h4>
                      </div>
                      <div className="sidebar__widget-content">
                        <ul>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Service Broohoru</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Visa Application Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border pdf-btm-none">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Admission Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="faqfrm__visa mb-30">
                      <div className="sidebar-title mb-40">
                        <h3>Ask Us Custom</h3>

                        </div>
                      <div className="faqfrm__visa-form">
                        <form action="#">
                          <input type="text" placeholder="Name*" />
                          <input type="email" placeholder="Email*" />
                          <input type="email" placeholder="Phone*" />
                          <select style={{display: 'none'}}>
                            <option> Subject</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                          </select><div className="nice-select" tabIndex={0}><span className="current"> Subject</span><ul className="list"><li data-value="Subject" className="option selected focus"> Subject</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li></ul></div>
                          <textarea cols={30} rows={10} defaultValue={""} />
                          <button className="theme-btn ">Submit Now</button>
                        </form>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="sidebar__widget-content-banner">
                          <img src={img_five} alt="" />
                          <div className="sidebar__widget-content-banner-text">
                            <span>Higher Study</span>
                            <h2>In Austalia</h2>
                            <a href="countries.html" className="banner-btn">Apply Visa</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-xxl-10 col-xl-10 col-lg-10">
                  <div className="sidebar__deatils">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={img_five}
                          alt=""
                          style={{ width: "100%", height: "350px" }}
                        />
                      </div>
                      <h3 className="united-states__title mb-15">
                        O U R V I S I O N A N D M I S S I O N :
                      </h3>
                      <p className="mb-30">
                        We aim to provide education and opportunities to as many
                        people across the world as possible. That is why we
                        provide study models that offer students:
                      </p>
                      <p className="mb-25">
                        1. Affordability <br />
                        2. Flexibility <br />
                        3. Quality education <br />
                        4. Career-focused, practical course content
                        <br />
                        5. An international network of fellow students and
                        industry partners. <br />
                      </p>
                    </div>
                    <div className="united-information pb-30">
                      <div className="row align-items-center">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                          <div className="united-info">
                            <h4
                              className="united-states__title2 mb-25"
                              style={{ fontSize: "19px" }}
                            >
                              G E R M A N Y B E N E F I T S :
                            </h4>
                            <ul>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  {" "}
                                  During studies, students can work 120 full
                                  days per year/20 hours per week{" "}
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  After graduation, students have the option to
                                  stay in Germany for up to 18 months{" "}
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  PR available under certain circumstances after
                                  2 to 3 years{" "}
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>High Quality Education</p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Low Tuition Fees</p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Vibrant Student Life</p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  {" "}
                                  <ul>
                                    <p> Career support </p>

                                    <li
                                      style={{
                                        listStyle: "disc",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <p> Career Service Department </p>
                                    </li>
                                    <li
                                      style={{
                                        listStyle: "disc",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <p>Individual Consulting Appointments </p>
                                    </li>
                                    <li
                                      style={{
                                        listStyle: "disc",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <p>Career Talks </p>
                                    </li>
                                    <li
                                      style={{
                                        listStyle: "disc",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <p>Career Workshops</p>
                                    </li>
                                    <li
                                      style={{
                                        listStyle: "disc",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <p>Career-Prep Program </p>
                                    </li>
                                    <li
                                      style={{
                                        listStyle: "disc",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <p>Job Teaser </p>
                                    </li>
                                    <li
                                      style={{
                                        listStyle: "disc",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <p>
                                        72% Graduates work in an international
                                        environment{" "}
                                      </p>
                                    </li>
                                    <li
                                      style={{
                                        listStyle: "disc",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <p>
                                        94% Of graduates are employed within 6
                                        months
                                      </p>
                                    </li>
                                  </ul>{" "}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                          <div className="united-thumb">
                            <img src={aus} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title2 mb-25">
                      POPULAR CAMPUS S T U D Y P R O G R A M S :
                    </h3>
                    <ul>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Pathway (one year Prep-course) </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor Business Administration </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor International Management </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor Computer Science</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor Business & IT </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor Industrial Engineering & Management </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor in Aviation Management </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor in Hospitality Management </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>M.Sc. Computer Science</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>M.A. International Management with majors </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>M.Eng. Engineering Management </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>MBA ( 1 TO 2 years on various fields)</p>
                      </li>
                    </ul>
                    {/* <h3 className="united-states__title3 mb-25">
                      Life Styles
                    </h3>
                    <p className="mb-35">An applicant should apply at center in or closest to, the city in which you are documented as being a resident. Please contact VFS Global if you have any doubts. This crossword explores the various paths humankind can follow to create a more sustainable world. Find out how many ways we can change our approach to the environment
                    </p>
                    <h3 className="united-states__title3 mb-20">
                      Law &amp; Rules
                    </h3>
                    <p className="mb-35">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                    </p> */}
                    {/* <div className="beautiful-cities mb-10">
                      <div className="row">
                        <div className="col-xxl-10 col-xl-10 col-lg-10">
                          <div className="beautiful-cites">
                            <h3 className="united-states__title3 mb-15">
                              COURSES OFFERED:
                            </h3>
                           
                            <div className="beautiful-link mb-30">
                              <ul>
                                <li><a className="active" href="#0">BACHELORS </a></li>
                                <li><a href="#0">MASTERS              </a></li>
                                <li><a href="#0">INTENSIVE ENGLISH LANGUAGE </a></li>
                                <li><a href="#0">COURSES </a></li>
                           
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                          <div className="beautiful-cites-place">
                            <img src="assets/img/united-states/butifull.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <h3 className="united-states__title3 mb-20 mt-35">
                      M A S T E R D E G R E E E N T R Y C R I T E R I A
                    </h3>
                    <ul>
                      <p>
                        {" "}
                        1. Bachelor’s degree certificate /Provisional
                        Certificate/ Graduation Certificate.
                      </p>
                      <p>
                        2. Consolidated transcript of records (minimum 50% of
                        overall GPA).{" "}
                      </p>
                      <p>3. Copy of int. passport </p>
                      <p>4. Resume </p>
                      <p>
                        5. English test: IELTS (min. Level 6), TOEFL (min.80
                        points), Duo lingo English test (min.95 points){" "}
                      </p>
                      <p>
                        6. Work Experience Certificate proven1-year working
                        experience after bachelor graduation.{" "}
                      </p>
                    </ul>
                    <p>
                      <span style={{ fontWeight: "500", color: "#000000a6" }}>
                        *Note:
                      </span>{" "}
                      offered-position letter, acceptance letter, resignation
                      letter, pay slips, work contract is not considered as work
                      experience certificate. Following Programs do not require
                      work experience but require related study field.{" "}
                    </p>
                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">
                        M B A E N T R Y C R I T E R I A
                      </h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ marginLeft: "15px" }}>
                            <p>
                              1. Bachelor’s degree certificate/ Provisional
                              Certificate/ Graduation Certificate.
                            </p>
                          </li>
                          <li style={{ marginLeft: "15px" }}>
                            <p>
                              2. Consolidated transcript of records (minimum 50%
                              of overall GPA).{" "}
                            </p>
                          </li>
                          <li style={{ marginLeft: "15px" }}>
                            <p>3. Copy of int. passport </p>
                          </li>
                          <li style={{ marginLeft: "15px" }}>
                            <p>4. Resume </p>
                          </li>
                          <li style={{ marginLeft: "15px" }}>
                            <p>
                              5. English test: IELTS (min. Level6), TOEFL
                              (min.80points), Duo lingo English test
                              (min.95points) (only accepted by few private
                              universities){" "}
                            </p>
                          </li>
                          <li style={{ marginLeft: "15px" }}>
                            <p>
                              6. Work Experience Certificate proven 2-years
                              working experience after bachelor graduation{" "}
                            </p>
                          </li>
                          {/* <li style={{   marginLeft: '15px'}}><p>NOTE:</P></li> */}

                          <li>
                            {" "}
                            <ul>
                              <p> NOTE: </p>

                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p> Career Service Department </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  {" "}
                                  offered-position letter, acceptance letter,
                                  resignation letter, pay slips, work contract
                                  is not considered as work experience
                                  certificate.{" "}
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>MBA 1 year or MBA 1.5 year </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  To start a 60-ECTS MBA program, you will need
                                  to have already acquired 210 ECTS credits. (4
                                  year mandatory study duration){" "}
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  To start a 90-ECTS MBA program, you will need
                                  to have already acquired 180 ECTS credits (3
                                  year mandatory study duration){" "}
                                </p>
                              </li>
                            </ul>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area start */}
          <div
            className="abbrand-area pb-120 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="container">
              <div className="abbrand-active owl-carousel">
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/1.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/2.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/3.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/5.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
    </main>
  );
};

export default Main;
