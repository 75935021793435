import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import "./style.css";
import Accordion from "react-bootstrap/Accordion";
import img_five from "../../assets/img/country-img/new.jpg";

import pross from "../../assets/img/country-img/pross.PNG";

import aus from "../../assets/img/country-img/australia.jpg";
import pro from "../../assets/img/country-img/pro.jpg";
import malta from "../../assets/img/country-img/malta.jpg";

import cyprus from "../../assets/img/country-img/cyprus.jpg";

import spain from "../../assets/img/country-img/spain.jpg";
import greece_ from "../../assets/img/country-img/greece_.jpg";

import Clops from "./Clops";
export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    return (
      <>
        <div>
          <Nav
            tabs
            style={{
              display: " flex",
              justifyContent: "center",
              marginBottom: "12px",
            }}
          >
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                USA
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                AUSTRALIA
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                PORTUGAL
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                MALTESE
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                CYPRUS
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "6" })}
                onClick={() => {
                  this.toggle("6");
                }}
              >
                SPAIN
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "7" })}
                onClick={() => {
                  this.toggle("7");
                }}
              >
           GREECE   
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div className="">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={img_five}
                          alt=""
                          style={{ width: "100%", height: "400px" }}
                        />
                      </div>
                      <div className="p-4">
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "16px", textAlign: "center" }}
                        >
                          USA EB-5 Immigrant Investor Program
                        </h3>

                        <p className="mb-25">
                          The EB-5 visa is an immigrant visa classification, so
                          it leads to a green card for successful applicants. By
                          getting an EB5 visa, your spouse and children can also
                          get their green card as well.{" "}
                        </p>
                        <h3 className="united-states__title mb-15">
                          process :
                        </h3>
                        <div className="united-states__thumb mb-40">
                          <img
                            src={pross}
                            alt=""
                            style={{ width: "100%", height: "150px" }}
                          />
                        </div>

                        <div className="united-information pb-30">
                          <div className="row align-items-center">
                            <div className="col-xxl-12 col-xl-12 col-lg-12">
                              <div className="united-info">
                                <h4
                                  className="united-states__title2 mb-25"
                                  style={{ fontSize: "19px" }}
                                >
                                  The top benefits of studying abroad in USA are
                                  –
                                </h4>
                                <ul>
                                  <li
                                    style={{
                                      listStyle: "disc",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    <p>
                                      •Investing at least $800,000 in a Targeted
                                      Employment Area (TEA). Investing in a
                                      rural area or an area which has a high
                                      rate of unemployment, in a
                                      government-approved program.
                                    </p>
                                  </li>
                                  <li
                                    style={{
                                      listStyle: "disc",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    <p>
                                      •Direct investment of at least $1.8
                                      million outside a TEA. You can invest in
                                      another company/enterprise of your choice
                                      or create your own company in the US, but
                                      you must make sure it meets the USCIS
                                      requirements.
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3 className="united-states__title2 mb-25 text-center">
                          USA EB-5 Immigrant Investor Program
                        </h3>

                        <h3 className="united-states__title3 mb-20">
                          Processing Time:
                        </h3>
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Standard petition to be adjudicated in about four
                              to five years{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              •Expedited petition to be processed in about two
                              to six months.
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Rural investment with premium processing to be
                              adjudicated in about a year (including petitions
                              from China .{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>as rural set-asides create a new lineup. </p>
                          </li>
                        </ul>
                        <h3 className="united-states__title3 mb-20">Cost</h3>
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>I-526 processing fee: $ 3,675 </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Form DS-260 processing fee: $ 200 </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>I-829 processing fee: $ 3,750</p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Medical examination fees</p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Any translation fees from foreign language to
                              English
                            </p>
                          </li>
                        </ul>
                        <p style={{ fontStyle: "italic" }}>
                          Consultancy Fees will be additional and will vary from
                          case to case{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <div className="">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={aus}
                          alt=""
                          style={{ width: "100%", height: "400px" }}
                        />
                      </div>
                      <div className="p-4">
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "16px", textAlign: "center" }}
                        >
                          AUSTRALIA ENTERPRENEUR STREAM 188 E
                        </h3>

                        <ul>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              This 188 E visa is only available to applicants
                              who are nominated by an Australian State or
                              Territory government. To be nominated, the
                              applicant must lodge an Expression of Interest
                              (EOI) through the SkillSelect system.
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              This is a 4-year temporary visa, for entrepreneurs
                              with innovative ideas and financial backing from a
                              third party. After spending 2 years in Australia
                              and being sponsored by a state or territory
                              government to qualify for a permanent residence
                              through the Business Innovation & Investment
                              (Residence) Subclass 888 visa.
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              The applicants can either be in Australia or
                              overseas to lodge this visa.
                            </p>
                          </li>
                        </ul>

                        <Clops />

                        <h3 className="united-states__title2 mb-25 pt-4 text-center">
                          AUSTRALIA ENTERPRENEUR STREAM 188 E
                        </h3>

                        <h3 className="united-states__title3 mb-20">
                          The proposed venture would need to be either:
                        </h3>
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Commercialisation of a product or service in
                              Australia{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Development of an enterprise or business in
                              Australia
                            </p>
                          </li>
                        </ul>
                        <h3 className="united-states__title3 mb-20 pt-2">
                          Other 188 Visa eligibility streams:
                        </h3>
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              BUSINESS INNOVTION for business owners seeking to
                              establish business operations in Australia{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              INVESTOR for people who are willing to invest $1.5
                              million in an Australian State or Territory bonds{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              SIGNIFICANT INVESTOR for people willing to invest
                              $5 million into complying investments in Australia
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              PREMIUM INVESTOR for people with significant funds
                              who are willing to invest at least $15 million
                              into complying premium investments in Australia.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <div className="">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={pro}
                          alt=""
                          style={{ width: "100%", height: "400px" }}
                        />
                      </div>
                      <div className="p-4">
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "16px", textAlign: "center" }}
                        >
                          PORTUGAL GOLDEN VISA PROGRAM
                        </h3>
                        <p className="mb-25">
                          The Portugal Golden Visa program, also known as the
                          residence permit for investment activity (ARI), is a
                          residency by investment scheme designed for non-EU
                          citizens.{" "}
                        </p>

                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey="0"
                            className="mb-3  border_color  shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              {" "}
                              What Are the Benefits of the Portugal Golden Visa?
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    You can qualify for residency by investing
                                    €280,000 which is one of the lowest
                                    investment thresholds in Europe,{" "}
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    You don’t have to move to Portugal to keep
                                    your residence permit. Staying in the
                                    country for seven days on average per year
                                    will suffice,
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    You can be eligible to apply for Portuguese
                                    citizenship in five years. This is one of
                                    the quickest in Europe,
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    Once you become a Portuguese citizen, you
                                    get visa-free access to 188 countries.
                                  </p>
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            className="mb-3   shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              Eligibility: Who Is Eligible for a Golden Visa in
                              Portugal?
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="mb-1">Nationalities </p>
                              <p className="mb-1">
                                Any non-EU/EEA/Swiss national can apply for the
                                Portugal Golden Visa.{" "}
                              </p>

                              <h3
                                className="united-states__title2 mb-2 pt-0 text-left"
                                style={{ fontSize: "16px" }}
                              >
                                Pre-requisites
                              </h3>
                              <p className="mb-5">
                                You’re required to be at least 18 years old and
                                hold a clean criminal record from your home
                                country, as well as from Portugal. The funds for
                                the investment should arrive from outside of
                                Portugal.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <h3
                          className="united-states__title2 mb-2 pt-0 text-left"
                          style={{ fontSize: "16px" }}
                        >
                          Portugal Golden Visa Requirements
                        </h3>
                        <p className="mb-5">
                          To get a Golden Visa for Portugal, you have to make
                          one of the following investments:
                        </p>

                        {/* <h3 className="united-states__title3 mb-20">
          The proposed venture would need to be either:
          </h3> */}
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Buy real estate worth at least €500,000. The
                              amount is brought down to €400,000 if the property
                              is in a low-density area.{" "}
                            </p>
                            <ul>
                              <li>
                                <p>
                                  Note: Starting from January 2022, you will not
                                  be able to purchase property in metropolitan
                                  areas of Portugal, such as Lisbon, Porto, or
                                  the coastal Algarve.{" "}
                                </p>
                              </li>
                            </ul>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Invest €350,000 in a property located in a
                              Portuguese urban regeneration area. The amount is
                              brought down to €280,000 if the property is in a
                              low-density area. The project you invest in must
                              be qualified by the Portuguese Immigration and
                              Borders Service (SEF).
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Invest at least €350,000 in a qualifying
                              Investment Fund.
                            </p>
                          </li>

                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Transfer at least €1 million in a Portuguese bank
                              account.
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Buy at least €1 million worth of shares or make an
                              investment of at least €1 million in a Portuguese
                              company.
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Create at least ten new jobs for Portuguese
                              citizens.
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Invest at least €350,000 for research activities
                              in science or technology in scientific research
                              institutions.
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Invest at least €250,000 to support the
                              reconstruction of Portuguese heritage or arts.
                            </p>
                          </li>
                        </ul>

                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey="0"
                            className="mb-3  border_color  shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              {" "}
                              Duration of the Portugal Golden Visa
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="mb-1">
                                When you apply for a Golden Visa, you will
                                receive a residence permit which is initially
                                valid for one year. After the first year, you
                                can apply for renewal and you will get a
                                two-year residence permit. You can keep renewing
                                your residence permit every two years, then
                                after five years, you can apply for a permanent
                                residence permit.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            className="mb-3   shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              Residency Requirements for the Portugal Golden
                              Visa
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="mb-1">
                                You only have to be in the country for seven
                                days per year in the first year to maintain the
                                residence permit, then at least 14 days for
                                every subsequent two-year period. That means
                                that at the end of the five year period which
                                makes you eligible for citizenship, you only
                                need to have spent 35 days in total in the
                                country.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item
                            eventKey="2"
                            className="mb-3   shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              Portugal Golden Visa to Permanent Residence
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="mb-1">
                                After you have maintained your investment for
                                five years, you can apply either for Portuguese
                                citizenship or permanent residency. If you have
                                a permanent residence permit, you can live and
                                work in Portugal indefinitely and travel to
                                other countries in the EU without a visa. The
                                permanent residence permit is valid for five
                                years, and you can keep renewing it
                                indefinitely. There are no language requirements
                                to get permanent residency, as opposed to
                                citizenship.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item
                            eventKey="3"
                            className="mb-3   shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              Portuguese Citizenship by Investment
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="mb-1">
                                Five years after you get a Golden Visa, you can
                                apply for Portuguese citizenship . You must
                                submit the application at the SEF, along with
                                documents proving the following:
                              </p>
                              <ul>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    That you have maintained your investment for
                                    the last five years{" "}
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    That you have spent at least 35 days in
                                    Portugal in the last five years
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    That you have passed the Portuguese language
                                    test.
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>That you have no criminal records.</p>
                                </li>
                              </ul>
                              <p className="mb-1 mt-4">
                                Obtaining a Portuguese passport means that you
                                have the same rights as other EU citizens,
                                including the right to free movement and work,
                                and visa-free access to over 180 countries.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <h3
                          className="united-states__title2 mb-2 pt-0 text-left"
                          style={{ fontSize: "16px" }}
                        >
                          Where do Family Members Stand?
                        </h3>
                        <p className="">
                          Your spouse, Children under the age of 18, Dependent
                          children up to age 26. They must be students and not
                          married. Parents, if they are over the age of 65 will
                          also get residence permits if you get a Golden Visa.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <div className="">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={malta}
                          alt=""
                          style={{ width: "100%", height: "400px" }}
                        />
                      </div>
                      <div className="p-4">
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "16px", textAlign: "center" }}
                        >
                          MALTESE CITIZENSHIP BY NATURALIZATION
                        </h3>
                        <h3
                          className="united-states__title3 mb-20"
                          style={{ fontSize: "16px" }}
                        >
                          Benefits of Maltese citizenship
                        </h3>
                        <ul>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              Visa-free or visa-on-arrival travel to 185
                              destinations.
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              Access to a transparent and reputable financial
                              centre and a prime jurisdiction for international
                              business
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              World’s strictest due diligence standards and
                              vetting of applicants, thus ensuring only highly
                              respectable applicants
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              An attractive place to live or own a second home
                              in, strategically located with excellent air
                              links.
                            </p>
                          </li>
                        </ul>

                        <h3
                          className="united-states__title3 mb-20"
                          style={{ fontSize: "16px" }}
                        >
                          Requirements of Maltese citizenship by naturalization
                        </h3>
                        <p>
                          The application for Malta Citizenship by
                          Naturalisation for Exceptional Services by Direct
                          Investment is thoroughly checked and assessed and
                          undergoes a four-tier due diligence process. To
                          qualify, the following requirements must be fulfilled:
                        </p>
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>A minimum age of 18 years for all applicants </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              A contribution of at least EUR 600,000 for a
                              minimum residence period of 36 months or EUR
                              750,000 for a minimum of 12 months
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              The purchase of a residential property in Malta of
                              at least EUR 700,000, which must be held for five
                              years. Alternatively, the lease of a residential
                              property with a rental value of at least EUR
                              16,000 per annum, also held for five years. Please
                              note that the property cannot be sublet during
                              this five-year period. A 36-month (or 18-month, by
                              exception) lease agreement or property purchase is
                              required during the residence period
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              A donation of at least EUR 10,000 to a registered
                              sport, cultural, scientific, philanthropic, animal
                              welfare, or artistic non-governmental organization
                              or society, as approved by the Community Malta
                              Agency
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Legal residence of Malta for at least 36 months
                              (or 12 months, by exception), which includes the
                              lease of a residential property with a rental
                              value of at least EUR 16,000 per annum, held for
                              that same period. Applicants require a valid
                              residence card in order to apply for citizenship
                            </p>
                          </li>
                        </ul>
                        <h3
                          className="united-states__title3 mb-20 pt-2"
                          style={{ fontSize: "16px" }}
                        >
                          Procedures and time frame
                        </h3>
                        <p>
                          Applications must be made on the prescribed forms and
                          be accompanied by the appropriate fees and specific
                          documents. Highly detailed due diligence checks are
                          carried out and an application will be declined if it
                          turns out that it contains false information or
                          omissions.
                        </p>
                        <p>
                          If the Tier 1 due diligence checks completed by the
                          Community Malta Agency are passed, the application for
                          a residence permit will be submitted. After residence
                          has been obtained, an eligibility application will be
                          submitted and further (tiers 2, 3, and 4) due
                          diligence checked will be made by the agency and
                          presented to the relevant Maltese minister, who will
                          decide on the applicant’s eligibility to apply for
                          citizenship.
                        </p>
                        <p>
                          If successful, a citizenship application will be
                          submitted to the Community Malta Agency after 36
                          months of residence (or 12 months by exception). After
                          the minister has decided if the applicant can be
                          granted Maltese citizenship, the successful applicant
                          will then be required to fulfil the exceptional
                          investment, donation, and property requirements.
                          Thereafter, the applicant will be invited to take the
                          oath of allegiance in Malta and be presented with a
                          Maltese certificate of naturalization.
                        </p>
                        <p>
                          The Community Malta Agency will conduct continuous
                          monitoring for five years.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="5">
              <Row>
                <Col sm="12">
                  <div className="">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={cyprus}
                          alt=""
                          style={{ width: "100%", height: "400px" }}
                        />
                      </div>
                      <div className="p-4">
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "16px", textAlign: "center" }}
                        >
                          CYPRUS RESIDENCY BY INVESTMENT
                        </h3>

                        <ul>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              A permanent permit under Regulation 6(2) of the
                              Cyprus Aliens and Immigration Regulations give to
                              an individual who invests to Cyprus the right to
                              permanently reside in Cyprus without the right of
                              employment.
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              This procedure is faster than the one described
                              under Category F above and the applicant can get
                              the permit normally within 6 months.
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              The applicant's family can also obtain it (spouse,
                              children under 18 years old, financially dependent
                              children up to 25 years old and parents/in-laws)
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              The applicant must meet one of the investment
                              criteria as well as the quality criteria outlined
                              below. It is noted that the money that will be
                              used for the investment must be proven to have
                              been transferred to Cyprus from abroad.
                            </p>
                          </li>
                        </ul>

                        <h3
                          className="united-states__title3 mb-20"
                          style={{ fontSize: "16px" }}
                        >
                          Investment criteria
                        </h3>
                        <p>
                          The applicant must invest at least €300,000 in one of
                          the following investment categories:
                        </p>
                        <ul>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              Investment in a house/apartment: Purchase a house
                              or apartment from a development company which
                              should concern a first sale of at least €300,000
                              plus VAT.{" "}
                            </p>
                          </li>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              Investment in real estate (excluding
                              houses/apartments): Purchase of other types of
                              real estate such as offices, shops, hotels or
                              related estate developments or a combination of
                              these with a total value of €300,000.
                            </p>
                          </li>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              Investment in Cyprus Company's share capital with
                              business activities and personnel in Cyprus:
                              Investment worth of €300,000 in the share capital
                              of a company registered in Cyprus, based and
                              operating in Cyprus and having proven physical
                              presence in Cyprus and employing at least five (5)
                              people.
                            </p>
                          </li>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              Investment in units of Cyprus Investment
                              Organization of Collective Investments (forms of
                              AIF, AIFLNP, RAIF): Investment worth of €300,000
                              in units of Cyprus Investment Organization
                              Collective Investments.
                            </p>
                          </li>
                        </ul>
                        <h3
                          className="united-states__title3 mb-20 pt-2"
                          style={{ fontSize: "16px" }}
                        >
                          Quality criteria
                        </h3>
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              The applicant and his spouse must submit clean
                              criminal record certificate from their country of
                              residence or from Cyprus in case they reside in
                              Cyprus.{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              {" "}
                              The applicant and his spouse shall also confirm
                              that they do not intend to undertake any sort of
                              employment in Cyprus.{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              In cases where the investment does not concern the
                              company's share capital, the applicant and/or his
                              spouse are allowed to be shareholders in companies
                              registered in Cyprus and the income from the
                              dividends derived from such companies may not be
                              considered as an obstacle for the purposes of
                              obtaining this Immigration Permit. In such
                              companies, they may also hold the position of an
                              unpaid Director.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="6">
              <Row>
                <Col sm="12">
                  <div className="">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={spain}
                          alt=""
                          style={{ width: "100%", height: "400px" }}
                        />
                      </div>
                      <div className="p-4">
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "16px", textAlign: "center" }}
                        >
                          SPAIN GOLDEN VISA PROGRAM
                        </h3>

                        <ul>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              Spain launched its golden visa programme in 2013.
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              An investment of €500,000 in real estate will gain
                              family residency.
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              The Spanish investor visa can be renewed every two
                              years. After five years it is possible to gain
                              permanent residency and after ten years
                              citizenship.
                            </p>
                          </li>
                          <li
                            style={{
                              listStyle: "disc",
                              marginLeft: "15px",
                            }}
                          >
                            <p>
                              It is not necessary to live in Spain in order to
                              retain and renew the residency visa permit.
                            </p>
                          </li>
                        </ul>

                        <h3
                          className="united-states__title3 mb-20"
                          style={{ fontSize: "16px" }}
                        >
                          Investment Options:
                        </h3>
                        <p>
                          According to the law Act 14/2013, of 27 September, of
                          support to entrepreneurs and their
                          internationalization” for a capital investment to be
                          considered as significant, one of the following
                          criteria must be fulfil:
                        </p>
                        <ul>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              Two million Euros in Spanish public debt
                              securities.{" "}
                            </p>
                          </li>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              One million Euros in company shares or stock of
                              Spanish capital companies with an actual business
                              activity.
                            </p>
                          </li>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              One million Euros in investment funds, closed-end
                              investment funds or venture capital funds
                              constituted in Spain, included within the scope of
                              application of Act 35/2003, of 4 November, on
                              Collective Investment Institutions, or of Act
                              22/2014, of 12 November, regulating venture
                              capital entities, other closed-end collective
                              investment entities and the management companies
                              of closed-end collective investment entities,
                              which amends Act 35/2003, of 4 November.
                            </p>
                          </li>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              <b>
                                One million Euros in bank deposits in Spanish
                                financial institutions.{" "}
                              </b>
                            </p>
                          </li>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p style={{ textDecoration: "underline" }}>
                              <b>
                                The acquisition of real estate property in Spain
                                with an investment value equal to or greater
                                than 500. 000 Euros ( FREE OF ANY LIENS OR
                                ENCUMBRANCES).{" "}
                              </b>
                            </p>
                          </li>
                          <li
                            style={{ listStyle: "number", marginLeft: "15px" }}
                          >
                            <p>
                              A business project intended to be carried out in
                              Spain that is deemed and proved to be of general
                              interest, being necessary to be considered as such
                              the fulfilment of at least one of the following
                              conditions:{" "}
                            </p>
                            <p>-The creation of jobs.</p>
                            <p>
                              {" "}
                              -Making an investment with relevant socio-economic
                              impact in the geographic area where the activity
                              will be carried out.
                            </p>
                            <p>
                              -A relevant contribution to scientific and/or
                              technological innovation.
                            </p>
                          </li>
                        </ul>

                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey="0"
                            className="mb-3  border_color  shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              {" "}
                              Who has to apply for the visa or residence permit?
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul>
                                <li
                                  style={{
                                    listStyle: "number",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    <b>AN INVESTOR VISA,</b> if you are outside
                                    of Spain when the application is submitted.
                                    Visa applications must be made at the
                                    Spanish Consulate in the country of origin
                                    or residence.{" "}
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "number",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    <b>A RESIDENCE PERMIT,</b> if you are
                                    currently in Spain when submitting the
                                    application. Residence permit applications
                                    must be made at the Large Business and
                                    Strategic Groups Unit (“Unidad de Grandes
                                    Empresas y Colectivos Estratégicos”
                                    (UGE-CE).
                                  </p>
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            className="mb-3   shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              Can family members benefit from the main applicant
                              investment?
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="mb-1">
                                Yes, they can also apply for a golden visa if
                                they are any of the following relationships
                              </p>
                              <ul>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    The spouse or unmarried couple (“civil
                                    partner”){" "}
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    Children who are minors or those of legal
                                    age being financially dependent on the
                                    holder.
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>Parents in their charge.</p>
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="2"
                            className="mb-3   shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              How long does the permit last of the golden visa
                              in Spain?
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="mb-1">
                                As you will see, the difference between
                                submitting the Golden Visa/ Investor Visa in
                                Spain or outside Spain affects the deadline for
                                the granting of the visa or residence permit:
                              </p>
                              <ul>
                                <li
                                  style={{
                                    listStyle: "number",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    <b>INVESTOR VISA:</b> 1 year.{" "}
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "number",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    <b>RESIDENCE PERMIT:</b> 2 years.
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "number",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                    <b>RENEWAL</b> of the residence permit: 5
                                    years and the renewal does not require
                                    effective residence in Spain
                                  </p>
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="7">
              <Row>
                <Col sm="12">
                  <div className="">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={greece_}
                          alt=""
                          style={{ width: "100%", height: "400px" }}
                        />
                      </div>
                      <div className="p-4">
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "16px", textAlign: "center" }}
                        >
                       GREECE GOLDEN VISA PROGRAM
                        </h3>
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "15px", textAlign: "center" }}
                        >
                   Benefits Of The Golden Visa Program!
                        </h3>
                        <p className="mb-25">
                        (Greek Permanent Residency Program and Schengen Visa to non-EU Nationals with the purchase of Property with minimum 
value 250,000Euros)
                        </p>
                        <p className="mb-25">
                        Greece Golden Visa offers your family the freedom to live freely in the EU and to travel with ease. It provides access to 
excellent education and healthcare, or simply offers an insurance policy for an uncertain future.'
                        </p>

                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "15px", textAlign: "left" }}
                        >
            What Is The Golden Visa Program?
                        </h3>
                        <p className="mb-25">
                        Greece Golden Visa program enables an entire non-EU family, including dependent children up to age 24 and the parents of 
both spouses, to obtain permanent residency status in Greece within just 3 months (in some areas it takes less time) with a real
estate investment of just €250,000. It is the most competitive permanent residency scheme in Europe.
                        </p>
                        <h3
                          className="united-states__title mb-15"
                          style={{ fontSize: "15px", textAlign: "left" }}
                        >
         Key Features :
                        </h3>

                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                            Includes dependent children up to age 24 (initially the age of a child shouldn't exceed 21, then s/he can extend till the 24th year 
of age)
                            </p>
                            <ul>
                              <li>
                                <p>
                                Includes the parents of both spouses
                                </p>
                              </li>
                            </ul>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                            3 month processing time (in some areas it takes less time, i.e. Chania, Crete in 10 days)
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Invest at least €350,000 in a qualifying
                              Investment Fund.
                            </p>
                          </li>

                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                            Gives the right to apply for EU citizenship
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                            Visa-free travel within Schengen zone
                            </p>
                          </li>
                         
                        </ul>

                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey="0"
                            className="mb-3  border_color  shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                              {" "}
                              Who Qualifies For The Golden Visa Program?
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                  Greece Golden Visa applies to the investor, their spouse, unmarried dependent children up to age 24 and the dependent parents 
of both spouses. The permit is permanent as long as the buyer keeps possession and is renewed every 5 years by presenting to 
the authority the title deed of the property. Obtaining permanent residency and eventually EU citizenship offers a legacy to your 
and later your children’s families.
                                  </p>
                                </li>
                              
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            className="mb-3   shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                            Which Are The Golden Visa Requirements?
                            </Accordion.Header>
                            <Accordion.Body>
                           
                              <p className="mb-1">
                              The Greece Golden Visa program requires a real estate investment of €250,000 (net of vat). The investment can be made in 
land, off-plan, new or resale properties as long as the minimum value is met. Investors have the right to sell the property before 
the expiration of 5 years to another foreign citizen, who can also apply for permanent residency, however, the original investor
must forfeit his permit to do so.
                              </p>

                              <ul>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                  In cases of joint ownership, where the value of the property is €250.000, the residence permit is only granted if the owners are
spouses with undivided ownership of the property. In all other cases of joint ownership, the Greece Golden Visa is only granted 
if the amount invested by each of the joint owners is at least €250.000.
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                  If the owner has acquired the property through a legal entity, the applicant must own 100% of the company shares.
                                  </p>
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                  Either a single or multiple properties with a combines value exceeding €250,000 are applicable. </p>
                                </li>
                              </ul>

                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item
                            eventKey="2"
                            className="mb-3   shadow-sm "
                          >
                            <Accordion.Header className="font30 extraBold">
                            How Can I Apply For A Citizenship In Greece?
                            </Accordion.Header>
                            <Accordion.Body>
                           
                              <p className="mb-1">
                              Permanent residents have the right to apply for Greek Citizenship after living in Greece for 7 years, in accordance with legal 
provisions. Their children may apply if:
                              </p>

                              <ul>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <p>
                                  they attend any school in Greece for 9 years, or they attend a secondary school in Greece for 6 years, orthey attend a University 
in Greece.
                                  </p>
                                </li>
                              
                              </ul>

                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </>
    );
  }
}
