/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import cOne from "../../assets/img/country-img/c-1.jpg";
import cTwo from "../../assets/img/country-img/c-2.jpg";
import cthree from "../../assets/img/country-img/c-3.jpg";
import cfour from "../../assets/img/country-img/c-4.jpg";
import cfive from "../../assets/img/country-img/c-5.jpg";

import flag11 from "../../assets/img/country-img/flag1-1.png";
import flag12 from "../../assets/img/country-img/flag1-2.png";
import flag13 from "../../assets/img/country-img/flag1-3.png";
import flag14 from "../../assets/img/country-img/flag1-4.png";
import flag15 from "../../assets/img/country-img/flag1-5.png";

export default () => {
  return (
    // className "owl-theme" is optional
    <OwlCarousel
      className="owl-theme countries-active "
      items={4}
      loop
      margin={10}
      nav
    >
      <div className="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cTwo} alt="" />
            <div className="countries-item__top-img-link">
              <a href="/australia">
                <i className="fal fa-plus" />
              </a>
            </div>
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-img mr-20">
            <img src={flag11} alt="" />
          </div>
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="/australia">Australia</a>
            </h5>
            <p>Study or migrate</p>
          </div>
        </div>
      </div>
      <div className="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cfour} alt="" />
            <div className="countries-item__top-img-link">
              <a href="/usa">
                <i className="fal fa-plus" />
              </a>
            </div>
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-img mr-20">
            <img src={flag12} alt="" />
          </div>
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="/usa">United States</a>
            </h5>
            <p>Study</p>
          </div>
        </div>
      </div>

      <div className="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cthree} alt="" />
            <div className="countries-item__top-img-link">
              <a href="/canada">
                <i className="fal fa-plus" />
              </a>
            </div>
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-img mr-20">
            <img src={flag15} alt="" />
          </div>
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="/canada">Canada</a>
            </h5>
            <p>Study or move</p>
          </div>
        </div>
      </div>
      <div className="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cfive} alt="" />
            <div className="countries-item__top-img-link">
              <a href="/germany">
                <i className="fal fa-plus" />
              </a>
            </div>
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-img mr-20">
            <img src={flag13} alt="" />
          </div>
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="/germany">Germany</a>
            </h5>
            <p>Study</p>
          </div>
        </div>
      </div>
      <div className="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cOne} alt="" />
            <div className="countries-item__top-img-link">
              <a href="/poland">
                <i className="fal fa-plus" />
              </a>
            </div>
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-img mr-20">
            <img src={flag14} alt="" />
          </div>
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="/poland">Poland</a>
            </h5>
            <p>Study</p>
          </div>
        </div>
      </div>
    </OwlCarousel>
  );
};
