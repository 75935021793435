import React, { useState, useEffect } from "react";

import "animate.css";
import footerbg from "../../assets/img/footer-bg/footer-bg.jpg";
import flogo from "../../assets/img/footer-logo/f-logo.png";

const Footer = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {}, []);

  return (
    <>
      <footer>
        <div
          className="footer__area footer-bg2 pt-70 pb-50"
          style={{ backgroundImage: "url(" + footerbg + ")" }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-sm-6 wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="footer__widget mb-30">
                  <div className="footer__widget-title">
                    <div className="logo mb-30">
                      <a href="index.html">
                        <img
                          src={flogo}
                          alt="logo"
                          style={{
                            width: 200,
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="footer__widget-content">
                    <div className="footer__logo-area">
                      <p>
                        Founded in 2019, Travelkook started its journey as
                        travel consultancy and gradually expanded themselves in
                        the field of immigration and education. Travelkook now
                        is partnered with over 241 universities all around the
                        world and have partner offices in over 12 countries of
                        the world. Our aim is to provide the best solution to
                        the client in the most affordable way.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-sm-6 wow fadeInUp"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="footer__widget margin-left-100 mb-30">
                  <div className="footer__widget-title mb-25">
                    <h2>Quick Links</h2>
                  </div>
                  <div className="footer__widget-content">
                    <div className="footer__services">
                      <ul>
                        <li>
                          <a href="/about">About Us</a>
                        </li>
                        <li>
                          <a href="/services">Services</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-sm-6 wow fadeInUp"
                data-wow-delay="0.7s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.7s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="footer__widget margin-left-30 mb-30">
                  <div className="footer__widget-title mb-25">
                    <h2>Visa Information</h2>
                  </div>
                  <div className="footer__widget-content">
                    <div className="footer__services">
                      <ul>
                        <li>
                          <a href="/business-visa">Business Visa</a>
                        </li>
                        <li>
                          <a href="/student-visa">Student Visa</a>
                        </li>
                        <li>
                          <a href="/work-job-visa">Work & Job Visa</a>
                        </li>
                        <li>
                          <a href="/tourist-visitor-visa">
                            Tourist & Visitor Visa
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-sm-6 wow fadeInUp"
                data-wow-delay="0.9s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.9s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="footer-widget mb-30">
                  <div className="footer__widget-title mb-30">
                    <h2>Contact Us</h2>
                  </div>
                  {/* <div className="subscribe-footer mb-35">
                    <form action="form.php">
                      <input type="email" placeholder="Enter your email" />
                      <button type="submit">
                        <i className="fal fa-long-arrow-right" />
                      </button>
                    </form>
                  </div> */}
                  <div className="footer__services ">
                    <ul>
                      <li>
                        <span>info@travelkook.com</span>{" "}
                      </li>
                      <li>
                        <span>Mob: +965-98726180</span>{" "}
                      </li>
                      <li>
                        {" "}
                        <div className="social mt-3">
                          <ul>
                            <li>
                              <a
                                href="https://www.facebook.com/travelkook/"
                                target="_blank"
                              >
                                <i className="fab fa-facebook-f" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.linkedin.com/company/travelkook/"
                                target="_blank"
                              >
                                <i className="fab fa-linkedin-in" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-coptright theme-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6">
                <div className="footer__text">
                  <p>
                    Copyright ©{new Date().getFullYear()} Travelkook. All Rights
                    Reserved
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="footer-copyright__wrapper footer-copyright__wrapper2">
                  <div className="footer-call d-flex align-items-center">
                    <div className="footer-copyright__wrapper__icon mr-10">
                      <i className="fal fa-headset" />
                    </div>
                    <div className="footer-copyright__wrapper__call-number">
                      <span>Call - Or - WhatsApp</span>
                      <h5>
                        <a href="tel:+965-98726180">+965-98726180</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
