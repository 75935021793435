/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import cfou from "../../assets/img/country-img/Germany.jpg";
import img_one from "../../assets/img/country-img/img_one.jpg";
import img_for from "../../assets/img/country-img/img_for.jpg";
import imge_two from "../../assets/img/country-img/imge_two.jpg";
import img_five from "../../assets/img/country-img/img_five.webp";

export default () => {
  // const options = {
  //     items: 1,
  //     nav: true,
  //     navText:["<div className='nav-btn prev-slide'></div>","<div className='nav-btn next-slide'></div>"],
  //     rewind: true,
  //     autoplay: true,
  //     slideBy: 1,
  //     dots: true,
  //     dotsEach: true,
  //     dotData: true
  //   };
  return (
    // className "owl-theme" is optional
    <OwlCarousel
      className="owl-theme abbrand-active "
      items={6}
      loop
      margin={10}
    >
      <div className="item  img-top pl-4 pr-4">
        <div className="abbrand-img contine_img">
          <img
            src={cfou}
            alt=""
            className="image"
            style={{ height: "150px" }}
          />
          <div className="overlay">
            <div className="text">GERMANY</div>
          </div>
        </div>
      </div>
      <div className="item  img-top pl-4 pr-4">
        <div className="abbrand-img contine_img">
          <img
            src={img_for}
            alt=""
            className="image"
            style={{ height: "150px" }}
          />
          <div className="overlay">
            <div className="text">POLAND</div>
          </div>
        </div>
      </div>
      <div className="item  img-top pl-4 pr-4">
        <div className="abbrand-img contine_img">
          <img
            src={imge_two}
            alt=""
            className="image"
            style={{ height: "150px" }}
          />
          <div className="overlay">
            <div className="text"> USA</div>
          </div>
        </div>
      </div>
      <div className="item  img-top pl-4 pr-4">
        <div className="abbrand-img contine_img">
          <img
            src={img_five}
            alt=""
            className="image"
            style={{ height: "150px" }}
          />
          <div className="overlay">
            <div className="text"> CANADA</div>
          </div>
        </div>
      </div>
    </OwlCarousel>
  );
};

// <div className="countries-active  owl-carousel">
// <div className=" img-top">
//   <div className="__top">
//     <div className="__top-img">
//       <img src="assets/img/country-img/c-2.jpg" alt="" />
//       <div className="__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="__bottom d-flex align-items-center">
//     <div className="__bottom-img mr-20">
//       <img src="assets/img/country-img/flag1.png" alt="" />
//     </div>
//     <div className="__bottom-content">
//       <h5 className="__bottom-content-title">
//         <a href="united-states.html">United States</a>
//       </h5>
//       <p>58 Universities</p>
//     </div>
//   </div>
// </div>
// <div className=" img-top">
//   <div className="__top">
//     <div className="__top-img">
//       <img src="assets/img/country-img/c-2.jpg" alt="" />
//       <div className="__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="__bottom d-flex align-items-center">
//     <div className="__bottom-img mr-20">
//       <img src="assets/img/country-img/flag2.png" alt="" />
//     </div>
//     <div className="__bottom-content">
//       <h5 className="__bottom-content-title">
//         <a href="united-states.html">New Zealand</a>
//       </h5>
//       <p>25 Universities</p>
//     </div>
//   </div>
// </div>
// <div className=" img-top">
//   <div className="__top">
//     <div className="__top-img">
//       <img src="assets/img/country-img/c-3.jpg" alt="" />
//       <div className="__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="__bottom d-flex align-items-center">
//     <div className="__bottom-img mr-20">
//       <img src="assets/img/country-img/flag3.png" alt="" />
//     </div>
//     <div className="__bottom-content">
//       <h5 className="__bottom-content-title">
//         <a href="united-states.html">United Kingdom</a>
//       </h5>
//       <p>33 Universities</p>
//     </div>
//   </div>
// </div>
// <div className=" img-top">
//   <div className="__top">
//     <div className="__top-img">
//       <img src="assets/img/country-img/c-4.jpg" alt="" />
//       <div className="__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="__bottom d-flex align-items-center">
//     <div className="__bottom-img mr-20">
//       <img src="assets/img/country-img/flag4.png" alt="" />
//     </div>
//     <div className="__bottom-content">
//       <h5 className="__bottom-content-title">
//         <a href="united-states.html">Switzerland</a>
//       </h5>
//       <p>38 Universities</p>
//     </div>
//   </div>
// </div>
// <div className=" img-top">
//   <div className="__top">
//     <div className="__top-img">
//       <img src="assets/img/country-img/c-5.jpg" alt="" />
//       <div className="__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="__bottom d-flex align-items-center">
//     <div className="__bottom-img mr-20">
//       <img src="assets/img/country-img/flag5.png" alt="" />
//     </div>
//     <div className="__bottom-content">
//       <h5 className="__bottom-content-title">
//         <a href="united-states.html">Australia</a>
//       </h5>
//       <p>33 Universities</p>
//     </div>
//   </div>
// </div>
// </div>
