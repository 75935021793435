import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import News from "./Pages/News";

import Faq from "./Pages/Faq";

import Countries from "./Pages/Countries";

import About from "./Pages/About";

import Services from "./Pages/Services";
import Contact from "./Pages/Contact";

import Poland from "./Pages/Poland";

import Usa from "./Pages/Usa";

import Germany from "./Pages/Germany";

import Canada from "./Pages/Canada";
import Australia from "./Pages/Australia";

import BusinessVisa from "./Pages/Visa/business";
import StudentVisa from "./Pages/Visa/student";
import WorkVisa from "./Pages/Visa/work";
import TouristVisa from "./Pages/Visa/tourist";

import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import "./assets/css/flaticon.css";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/countries" element={<Countries />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/poland" element={<Poland />} />
        <Route path="/usa" element={<Usa />} />
        <Route path="/germany" element={<Germany />} />
        <Route path="/canada" element={<Canada />} />
        <Route path="/australia" element={<Australia />} />

        <Route path="/business-visa" element={<BusinessVisa />} />
        <Route path="/student-visa" element={<StudentVisa />} />
        <Route path="/work-job-visa" element={<WorkVisa />} />
        <Route path="/tourist-visitor-visa" element={<TouristVisa />} />
      </Routes>
    </div>
  );
};

export default App;
