import React from "react";

import { Link } from "react-router-dom";
import visabnr from "../../../assets/img/visa/v-1.jpg";

const Main = () => {
  return (
    <main>
      <div>
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + visabnr + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">Student Visa</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">Student Visa</li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <section
          className="blog-area pt-60 pb-30 wow fadeInUp"
          data-wow-delay="0.3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
        >
          <div className="container">
            <div className="row">
              <p>
                Any student who wants to study in a foreign country would
                require a student visa to enter the new country and begin his or
                her education. Travelkook offers student admission package along
                with the visa assistance for the following countries:
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">AUSTRALIA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">CANADA</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">GERMANY</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">POLAND</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">UNITED KINGDOM</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">UNITED STATES OF AMERICA</p>
                </li>
              </ul>
              <p className="pt-15 pb-0">
                <h6>How To Get a Student Visa?</h6>
              </p>
              <p className="pt-0 pb-10">
                The application process for a student visa goes as follows:
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Apply for a study program.</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Make an appointment at the embassy or consulate.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Gather your documents.</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Submit the visa payment.</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Attend your student visa interview.</p>
                </li>
              </ul>
              <p className="pt-15 pb-0">
                General Documents Required for application of Student Visa are:
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Passport (with six-month validity).</b> Your passport
                    must be valid for at least six months when you apply for
                    your visa.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Student visa application form.</b> You need to fill out
                    the student visa application form with your details— you can
                    usually find the form at the embassy/consulate as well as
                    online. We generally provide this to our clients.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Passport pictures.</b> You must also submit a few
                    passport pictures that you took recently— the number of
                    pictures required as well as the photo specifications varies
                    from country to country.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Proof of admission.</b> Submit your admission letter or
                    enrollment records to show that you have been accepted to a
                    study program in your destination country.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Evidence of financial means.</b> You must prove that you
                    can financially support yourself during your study program.
                    If your family supports you, provide financial statements
                    from your family members. If you have a scholarship, you
                    need to attach proof that you are a financial aid recipient.
                  </p>
                </li>
              </ul>
              <p className="pt-15 pb-0">
                Other documents that you can submit as financial proof are:
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Letter of Sponsorship from parents or siblings or spouse
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Student loan or grant</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Scholarship</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Blocked Bank account (specially for Germany or GIC for
                    Canada)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Bank Statements</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Civil documents.</b> You must submit your birth
                    certificate, marriage certificate (if you are married), and
                    your CV.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>International student health insurance.</b> Depending on
                    the country that you want to study in, you might be required
                    to purchase a health insurance plan, that covers the entire
                    period of your studies.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Previous university records.</b> If you attended a
                    university prior to applying for your foreign study program,
                    you must submit your university records for the student visa
                    application.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Police Verification Certificate</b> – not all countries
                    require this. Only specific countries ask for this.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    <b>Medical Exam Certificate</b> – only for specific
                    countries
                  </p>
                </li>
              </ul>

              <p className="pt-15 pb-0">
                <h6>How Long Does a Student Visa Last?</h6>
              </p>
              <p className="pt-15 pb-0">
                A student visa lasts for the duration of your educational
                program. So, if you will be studying for two years in a foreign
                country, your visa will be issued for the same amount of time.
                Most study visas are issued anywhere between two to four years.
              </p>

              <p className="pt-15 pb-0">
                <h6>How Long Does it Take to Get a Student Visa?</h6>
              </p>
              <p className="pt-15 pb-0">
                It takes several months to finish processing your student visa
                application. Some countries may finish faster than others,
                depending on how complete your application is and how many
                people have applied for student visas.
              </p>

              <p className="pt-15 pb-0">
                <h6>Can I Extend a Student Visa?</h6>
              </p>
              <p className="pt-15 pb-0">
                Some countries may allow you to extend your student visa if you
                are behind on your study program. However, the visa extension
                policy is different in every country, so you need to check with
                the embassy and your education provider.
              </p>

              <p className="pt-15 pb-0">
                <h6>Can I Bring Family Members With Me on a Student Visa?</h6>
              </p>
              <p className="pt-15 pb-0">
                Yes, but you can usually only bring your partner/spouse and
                minor children. Please remember that your family members will
                also need to apply for their visas.
              </p>
            </div>
          </div>
        </section>

        {/* JS here */}
      </div>
    </main>
  );
};

export default Main;
