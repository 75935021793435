import React from "react";

import { Link } from "react-router-dom";
import cTwo from "../../assets/img/blog/blog1.jpg";
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";
import Tabs from "./Tabs";

import cfou from "../../assets/img/country-img/Germany.jpg";
import img_one from "../../assets/img/country-img/img_one.jpg";
import img_for from "../../assets/img/country-img/img_for.jpg";
import two from "../../assets/img/country-img/two.jpg";
import img_five from "../../assets/img/country-img/australia.jpg";

import aus from "../../assets/img/country-img/aus.png";
import Slide from "./Sliders-two";
const Main = () => {
  return (
    <main>
      <div>
        {/* page title area start */}
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + two + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">STUDY IN AUSTRALIA</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">
                {" "}
                <Link to="/">Australia</Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          <div className="united-stats-area pt-120">
            <div className="container">
              <div className="row justify-content-center">
                {/* <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <div className="sidebar-left__wrapper">
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="cat-link">
                          <ul>
                            <li><a className="vrbg" href="united-states.html">Australia</a></li>
                            <li><a href="united-states.html">Bangladesh</a></li>
                            <li><a href="united-states.html">Switzerland</a></li>
                            <li><a href="united-states.html">Norway</a></li>
                            <li><a href="united-states.html">Germany</a></li>
                            <li><a href="united-states.html">United Kingdom</a></li>
                            <li><a href="united-states.html">France</a></li>
                            <li><a href="united-states.html">South Africa</a></li>
                            <li><a href="united-states.html">Canada</a></li>
                            <li><a href="united-states.html">Denmark</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30 theme-bg pd-30">
                      <div className="sidebar__widget-title title-white mb-40">
                        <h4>Download</h4>
                      </div>
                      <div className="sidebar__widget-content">
                        <ul>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Service Broohoru</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Visa Application Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border pdf-btm-none">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Admission Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="faqfrm__visa mb-30">
                      <div className="sidebar-title mb-40">
                        <h3>Ask Us Custom</h3>
                      </div>
                      <div className="faqfrm__visa-form">
                        <form action="#">
                          <input type="text" placeholder="Name*" />
                          <input type="email" placeholder="Email*" />
                          <input type="email" placeholder="Phone*" />
                          <select style={{display: 'none'}}>
                            <option> Subject</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                          </select><div className="nice-select" tabIndex={0}><span className="current"> Subject</span><ul className="list"><li data-value="Subject" className="option selected focus"> Subject</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li></ul></div>
                          <textarea cols={30} rows={10} defaultValue={""} />
                          <button className="theme-btn ">Submit Now</button>
                        </form>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="sidebar__widget-content-banner">
                          <img src={img_five} alt="" />
                          <div className="sidebar__widget-content-banner-text">
                            <span>Higher Study</span>
                            <h2>In Austalia</h2>
                            <a href="countries.html" className="banner-btn">Apply Visa</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-xxl-10 col-xl-10 col-lg-10">
                  <div className="sidebar__deatils">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={img_five}
                          alt=""
                          style={{ width: "100%", height: "350px" }}
                        />
                      </div>
                      {/* <h3 className="united-states__title mb-15">
                      Australia
                      </h3>
                      <p className="mb-30">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                      </p>
                      <p className="mb-25">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                      </p> */}
                    </div>
                    <div className="united-information pb-30">
                      <div className="row align-items-center">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                          <div className="united-info">
                            <h4
                              className="united-states__title2 mb-25"
                              style={{ fontSize: "19px" }}
                            >
                              BENEFITS FOR STUDYING IN AUSTRALIA
                            </h4>
                            <ul>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>BENEFITS FOR STUDYING IN AUSTRALIA:</p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  8 of the world’s top Universities are located
                                  in Australia
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  22,000 course options at one of the 1,100
                                  globally recognised institutions Quality of
                                  education
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Cultural diversity</p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Post study work opportunities</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                          <div className="united-thumb">
                            <img src={aus} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title2 mb-25">
                      ADMISSION REQUIREMENTS :
                    </h3>
                    <ul>
                      {" "}
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">Covid Vaccination Certificate </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          RESUME/ CV: An updated CV/ RESUME with complete
                          details of your education, profession and personal
                          details. If your CV is not updated kindly update it
                          and send it in order to avoid any delay.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          {" "}
                          10 Marks Sheet: 10th / SSC/ISC/ICSE/CBSE certificate,
                          which has to be NOTARIZED{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          10+2 / Equivalent mark sheet: Intermediate/ HSC
                          certificate, which has to be NOTARIZED.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Bachelors Consolidated Memo and Provisional : All
                          certificates from the first year till the end of
                          fourth year (Individual memos) including the
                          provisional certificate, consolidated memos and course
                          completion certificates, which are NOTORIZED should be
                          submitted with the application{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Diploma / Post Graduation Diploma/ Masters: If student
                          has completed any diploma or Pg diploma/ Masters a
                          NOTORIZED Copy of the same should be submitted along
                          with application.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          TOEFL / IELTS / PTE : The most recent score report
                          which proves your English Proficiency, a NOTORZIED
                          document has to submitted.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Passport copy : A NOTORIZED copy of the first and the
                          last page on single page of a valid passport should be
                          submitted.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Statement of Purpose (SOP) :(Approx. 750 words) To be
                          prepared by the student on A4 size paper and duly
                          signed. Hard & Soft copy of the same should be
                          submitted.
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Other Certificates (if any) : Photocopies of any
                          extracurricular activities, any awards or achievements
                          can also be enclosed.
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Experience Letter : If a student has more than 6
                          months’ experience, then a copy of the Experience
                          Letter should be submitted with the application.{" "}
                          <br />
                          - Work Experience if any
                          <br /> – Offer letter, latest 3 payslips,6 months Bank
                          statement and form-16/ ITR’s, SOP, Resume.
                          <br />- Still not working –Relieving Letter from
                          Organisation
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Id proof(applicant) : Aadhaar Card, Pan Card.
                        </p>
                      </li>
                    </ul>
                    {/* <h3 className="united-states__title3 mb-25">
                      Life Styles
                    </h3>
                    <p className="mb-35">An applicant should apply at center in or closest to, the city in which you are documented as being a resident. Please contact VFS Global if you have any doubts. This crossword explores the various paths humankind can follow to create a more sustainable world. Find out how many ways we can change our approach to the environment
                    </p>
                    <h3 className="united-states__title3 mb-20">
                      Law &amp; Rules
                    </h3>
                    <p className="mb-35">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                    </p> */}
                    <div className="beautiful-cities mb-10">
                      <div className="row">
                        <div className="col-xxl-10 col-xl-10 col-lg-10">
                          <div className="beautiful-cites">
                            <h3 className="united-states__title3 mb-15">
                              COURSES OFFERED:
                            </h3>

                            <div className="beautiful-link mb-30">
                              <ul>
                                <li>
                                  <a className="active" href="#0">
                                    BACHELORS{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="#0">MASTERS </a>
                                </li>
                                <li>
                                  <a href="#0">INTENSIVE ENGLISH LANGUAGE </a>
                                </li>
                                <li>
                                  <a href="#0">COURSES </a>
                                </li>
                                {/* <li><a href="#0">California, </a></li>
                                <li><a href="#0">Florida, </a></li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                          <div className="beautiful-cites-place">
                            <img
                              src="assets/img/united-states/butifull.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title3 mb-20">
                      POPULAR BACHELOR AND MASTER COURSES IN AUSTRALIA:
                    </h3>
                    <ul>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor of Information Technology</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor of Accounting</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor of Engineering</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Bachelor of Nursing</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Master of Business Administration</p>
                      </li>

                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Masters of Research</p>
                      </li>

                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Masters of Agricultural Science </p>
                      </li>
                    </ul>

                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">
                        WHAT WE OFFER:
                      </h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>CAREER COUNSELLING</p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>ACCESS TO BEST EDUCATIONAL UNIVERSITIES</p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>ADMISSION ASSISTANCE </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>VISA ASSISTANCE </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>PR PATHWAY </p>
                          </li>

                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>LODGING ASSISTANCE </p>
                          </li>

                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>PREPARE FOR IELTS AND OTHER COURSES </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              ESSAY WRITING TRAVELKOOK IS A ONE STOP SOLUTION
                              FOR ALL INTERNATIONAL STUDENTS
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">FAQS</h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Tuition Fees varying between 20,000 and 45,000
                              Australian dollars per year
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Scholarship Options</p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Assistance from Specialized Career Counsellor to
                              choose the right course for studying{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Intensive English courses for 4 to 8 months
                              depending upon the requirement of the student{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Checking visa condition of the individual students
                              and providing assistance with it{" "}
                            </p>
                          </li>

                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Student may do part time jobs while they study
                              depending upon the criteria of their university{" "}
                            </p>
                          </li>

                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>PR Options </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Landing Support in Australia.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area start */}
          <div
            className="abbrand-area pb-120 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="container">
              <div className="abbrand-active owl-carousel">
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/1.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/2.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/3.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/5.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
    </main>
  );
};

export default Main;
