import React from "react";

import { Link } from "react-router-dom";
import cTwo from "../../assets/img/blog/blog1.jpg";
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";
import Tabs from "./Tabs";

import cfou from "../../assets/img/country-img/Germany.jpg";
import img_one from "../../assets/img/country-img/img_one.jpg";
import img_for from "../../assets/img/country-img/img_for.jpg";
import two from "../../assets/img/country-img/new.jpg";
import img_five from "../../assets/img/country-img/new-yorko.jpg";

import aus from "../../assets/img/country-img/newmap.jpg";
import Slide from "./Sliders-two";
const Main = () => {
  return (
    <main>
      <div>
        {/* page title area start */}
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + two + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">STUDY IN USA</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">
                {" "}
                <Link to="/">Usa</Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          <div className="united-stats-area pt-120">
            <div className="container">
              <div className="row justify-content-center">
                {/* <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <div className="sidebar-left__wrapper">
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="cat-link">
                          <ul>
                            <li><a className="vrbg" href="united-states.html">USA</a></li>
                            <li><a href="united-states.html">Bangladesh</a></li>
                            <li><a href="united-states.html">Switzerland</a></li>
                            <li><a href="united-states.html">Norway</a></li>
                            <li><a href="united-states.html">Germany</a></li>
                            <li><a href="united-states.html">United Kingdom</a></li>
                            <li><a href="united-states.html">France</a></li>
                            <li><a href="united-states.html">South Africa</a></li>
                            <li><a href="united-states.html">Canada</a></li>
                            <li><a href="united-states.html">Denmark</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30 theme-bg pd-30">
                      <div className="sidebar__widget-title title-white mb-40">
                        <h4>Download</h4>
                      </div>
                      <div className="sidebar__widget-content">
                        <ul>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Service Broohoru</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Visa Application Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border pdf-btm-none">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Admission Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="faqfrm__visa mb-30">
                      <div className="sidebar-title mb-40">
                        <h3>Ask Us Custom</h3>
                      </div>
                      <div className="faqfrm__visa-form">
                        <form action="#">
                          <input type="text" placeholder="Name*" />
                          <input type="email" placeholder="Email*" />
                          <input type="email" placeholder="Phone*" />
                          <select style={{display: 'none'}}>
                            <option> Subject</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                          </select><div className="nice-select" tabIndex={0}><span className="current"> Subject</span><ul className="list"><li data-value="Subject" className="option selected focus"> Subject</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li></ul></div>
                          <textarea cols={30} rows={10} defaultValue={""} />
                          <button className="theme-btn ">Submit Now</button>
                        </form>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="sidebar__widget-content-banner">
                          <img src={img_five} alt="" />
                          <div className="sidebar__widget-content-banner-text">
                            <span>Higher Study</span>
                            <h2>In Austalia</h2>
                            <a href="countries.html" className="banner-btn">Apply Visa</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-xxl-10 col-xl-10 col-lg-10">
                  <div className="sidebar__deatils">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={img_five}
                          alt=""
                          style={{ width: "100%", height: "350px" }}
                        />
                      </div>
                      <h3
                        className="united-states__title mb-15"
                        style={{ fontSize: "17px" }}
                      >
                        With World’s largest Population of International
                        Students, U.S. degrees have an excellent international
                        reputation with having world’s 17 top Universities. The
                        US universities have excellent support facilities. They
                        are technology most advanced along with flexible
                        academic environment. They have cultural diversity which
                        helps student to study in an unique diverse environment.
                        US universities also have the scope for various career
                        and research opportunities .
                      </h3>
                      <h3 className="united-states__title mb-15">WHY USA :</h3>
                      <p className="mb-25">
                        The vast opportunities that educational institutions in
                        the USA provide are rarely found in any other country.{" "}
                      </p>
                      <p className="mb-25">
                        Technology, Engineering and Mathematics) programs and
                        equal appreciation for Arts and Literature subjects,
                        there are many other benefits for studying in USA.
                      </p>
                    </div>
                    <div className="united-information pb-30">
                      <div className="row align-items-center">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                          <div className="united-info">
                            <h4
                              className="united-states__title2 mb-25"
                              style={{ fontSize: "19px" }}
                            >
                              The top benefits of studying abroad in USA are –
                            </h4>
                            <ul>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  The USA is home to some of the best
                                  educational institutions in the world. The
                                  degrees from these universities are recognised
                                  worldwide.{" "}
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  The higher education system in USA is quite
                                  flexible in terms of deciding the structure
                                  and courses.{" "}
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  The US colleges and universities offer a
                                  vibrant student life. Universities offer a
                                  wide range of activities beyond academics in
                                  their campus.{" "}
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  There are ample research and training
                                  opportunities for international students
                                  studying in USA. The universities are well
                                  equipped with the latest technology to provide
                                  up-to-date education, research and training to
                                  their students.
                                </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>
                                  Studying from the best US universities and
                                  learning under the guidance of top professors
                                  in the world, make students highly employable
                                  in the job market.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                          <div className="united-thumb">
                            <img src={aus} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title2 mb-25">
                      EDUCATIONAL SYSTEM:
                    </h3>
                    <ul>
                      {" "}
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Undergraduate Degree: It is a full-time undergraduate
                          degree which is referred to as a bachelor’s degree in
                          USA. The duration of the degree is 4 years.
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Associate Degree: It is a degree taken at an
                          undergraduate level. It is equivalent to a diploma and
                          is offered by community colleges in USA. It is of 2
                          years duration.
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          {" "}
                          Graduate Degree: A graduate or master’s degree mostly
                          covers 2 to 3 academic years in the universities of
                          USA.
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Doctorate Degree: A doctorate degree takes a total of
                          5 to 8 years to complete.
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          Credit System in US: All US universities follow the
                          same credit system. The credits that a student earns
                          during the course are taken into consideration to
                          calculate the result.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          A student requires 3-5 credits per course for an
                          undergraduate degree/Bachelors. Typically, around
                          120-130 credit hours are required to complete a
                          bachelor's degree{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          For a master’s course, it is 3-4 credits per course.
                          Around 30-64 credit hours are required for a master's
                          degree in USA.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                        <p className="mb-15">
                          The U.S. education system uses the GPA or the grade
                          point average, which is a scale from 0.0 to 4.0 to
                          grade students. For a semester, these are calculated
                          based on marks obtained in each course and credits
                          earned for that semester. 4.0 represents A grade and
                          0.0 is equivalent to F grade.
                        </p>
                      </li>
                    </ul>
                    {/* <h3 className="united-states__title3 mb-25">
                      Life Styles
                    </h3>
                    <p className="mb-35">An applicant should apply at center in or closest to, the city in which you are documented as being a resident. Please contact VFS Global if you have any doubts. This crossword explores the various paths humankind can follow to create a more sustainable world. Find out how many ways we can change our approach to the environment
                    </p>
                    <h3 className="united-states__title3 mb-20">
                      Law &amp; Rules
                    </h3>
                    <p className="mb-35">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                    </p> */}
                    <div className="beautiful-cities mb-10">
                      <div className="row">
                        <div className="col-xxl-10 col-xl-10 col-lg-10">
                          <div className="beautiful-cites">
                            <h3 className="united-states__title3 mb-15">
                              COURSES OFFERED:
                            </h3>

                            <div className="beautiful-link mb-30">
                              <ul>
                                <li>
                                  <a className="active" href="#0">
                                    BACHELORS{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="#0">MASTERS </a>
                                </li>
                                <li>
                                  <a href="#0">PHD</a>
                                </li>
                                <li>
                                  <a href="#0">ENGLISH LANGUAGE COURSES </a>
                                </li>
                                <li>
                                  <a href="#0">SHORT TERM COURSES </a>
                                </li>
                                {/* <li><a href="#0">California, </a></li>
                                <li><a href="#0">Florida, </a></li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                          <div className="beautiful-cites-place">
                            <img
                              src="assets/img/united-states/butifull.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title3 mb-20">
                      POPULAR COURSES:
                    </h3>
                    <ul>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Engineering & Technology.</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Business and Management.</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Marine Biology and Life Sciences. </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Maths & Computer Science. </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Social Sciences. </p>
                      </li>

                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Medicine. </p>
                      </li>

                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Liberal Arts. </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Law </p>
                      </li>
                    </ul>

                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">FAQS :</h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p> LANGUAGE OF INSTRUCTION ENGLISH. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>AVERAGE COST OF STUDY 45000 USD PER YEAR. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              AVERAGE COST OF LIVING 10,000 TO 20,000 USD PER
                              YEAR. SCHOLARSHIPS,{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>STUDENT AIDS AND PART TIME JOBS, </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>IELTS, TOEFL MANDATORY, </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>GRE, GMAT REQUIRED IN SOME CASES, </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>GRADUATE, </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>UNDERGRADUATE, </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>ASSOCIATE AND DOCTORATE DEGREE AVAILABLE. </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">
                        DOCUMENT CHECKLIST FOR USA :
                      </h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p> Passport. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Resume/Curriculum Vitae. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Transcripts. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Marksheets and pass certificates. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>High school diploma or equivalent. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Bachelor’s degree or equivalent for Master’s
                              Degree Courses.{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Letters of Recommendation.</p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Entrance Exam Scorecards - GRE / GMAT / LSAT /
                              MCAT / SAT / ACT – course wise.
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              English Language Proficiency Test Scores – IELTS /
                              TOEFL.{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Statement of Purpose. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Experience Letters (if any). </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Application confirmation copy v Essays. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Certificates for extra-curricular activities.{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Proof of Funds. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Research proposal/ Portfolio/ Project Description
                              (for specific courses – specially PHD).{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Marksheets and pass certificates. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Letters of Recommendation</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area start */}
          <div
            className="abbrand-area pb-120 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="container">
              <div className="abbrand-active owl-carousel">
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/1.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/2.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/3.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/5.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
    </main>
  );
};

export default Main;
