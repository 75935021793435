import React from "react";

import { Link } from "react-router-dom";
import cTwo from "../../assets/img/blog/blog1.jpg";
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";
import Tabs from "./Tabs";

import cfou from "../../assets/img/country-img/Germany.jpg";
import img_one from "../../assets/img/country-img/img_one.jpg";
import img_for from "../../assets/img/country-img/img_for.jpg";
import two from "../../assets/img/country-img/canadao.jpg";
import img_five from "../../assets/img/country-img/imge_two.jpg";

import aus from "../../assets/img/country-img/canadamap.png";
import Slide from "./Sliders-two";
const Main = () => {
  return (
    <main>
      <div>
        {/* page title area start */}
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + two + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">STUDY IN CANADA</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">
                {" "}
                <Link to="/">Canada</Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          <div className="united-stats-area pt-120">
            <div className="container">
              <div className="row justify-content-center">
                {/* <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <div className="sidebar-left__wrapper">
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="cat-link">
                          <ul>
                            <li><a className="vrbg" href="united-states.html">CANADA</a></li>
                            <li><a href="united-states.html">Bangladesh</a></li>
                            <li><a href="united-states.html">Switzerland</a></li>
                            <li><a href="united-states.html">Norway</a></li>
                            <li><a href="united-states.html">Germany</a></li>
                            <li><a href="united-states.html">United Kingdom</a></li>
                            <li><a href="united-states.html">France</a></li>
                            <li><a href="united-states.html">South Africa</a></li>
                            <li><a href="united-states.html">Canada</a></li>
                            <li><a href="united-states.html">Denmark</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30 theme-bg pd-30">
                      <div className="sidebar__widget-title title-white mb-40">
                        <h4>Download</h4>
                      </div>
                      <div className="sidebar__widget-content">
                        <ul>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Service Broohoru</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Visa Application Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                          <li className="d-flex align-items-center mb-20 pdf-btm-border pdf-btm-none">
                            <div className="docu__thumb mr-15">
                              <a href="contact.html"><i className="fas fa-file-pdf" /></a>
                            </div>
                            <div className="docu__text">
                              <h6><a href="contact.html">Admission Form</a></h6>
                              <p>PDF<span>12Mb</span></p> 
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="faqfrm__visa mb-30">
                      <div className="sidebar-title mb-40">
                        <h3>Ask Us Custom</h3>
                      </div>
                      <div className="faqfrm__visa-form">
                        <form action="#">
                          <input type="text" placeholder="Name*" />
                          <input type="email" placeholder="Email*" />
                          <input type="email" placeholder="Phone*" />
                          <select style={{display: 'none'}}>
                            <option> Subject</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                            <option> Choose an option</option>
                          </select><div className="nice-select" tabIndex={0}><span className="current"> Subject</span><ul className="list"><li data-value="Subject" className="option selected focus"> Subject</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li><li data-value="Choose an option" className="option"> Choose an option</li></ul></div>
                          <textarea cols={30} rows={10} defaultValue={""} />
                          <button className="theme-btn ">Submit Now</button>
                        </form>
                      </div>
                    </div>
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="sidebar__widget-content-banner">
                          <img src={img_five} alt="" />
                          <div className="sidebar__widget-content-banner-text">
                            <span>Higher Study</span>
                            <h2>In Austalia</h2>
                            <a href="countries.html" className="banner-btn">Apply Visa</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-xxl-10 col-xl-10 col-lg-10">
                  <div className="sidebar__deatils">
                    <div className="united-states">
                      <div className="united-states__thumb mb-40">
                        <img
                          src={img_five}
                          alt=""
                          style={{ width: "100%", height: "350px" }}
                        />
                      </div>
                      {/* <h3 className="united-states__title mb-15">
                      CANADA
                      </h3>
                      <p className="mb-30">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                      </p>
                      <p className="mb-25">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                      </p> */}
                    </div>
                    <div className="united-information pb-30">
                      <div className="row align-items-center">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                          <div className="united-info">
                            <h4
                              className="united-states__title2 mb-25"
                              style={{ fontSize: "19px" }}
                            >
                              BENEFITS FOR STUDYING IN CANADA:
                            </h4>
                            <ul>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p> 100+ Public and Private Institutions </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>More than 30% International Students </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Academic Excellence </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Affordability </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Cultural Diversity </p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Part Time Job Opportunities for Students</p>
                              </li>
                              <li
                                style={{
                                  listStyle: "disc",
                                  marginLeft: "15px",
                                }}
                              >
                                <p>Immigration Opportunities </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                          <div className="united-thumb">
                            <img src={aus} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title2 mb-25">
                      Eligibility Criteria for Canadian Universities :
                    </h3>
                    <ul>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: " 400",
                              color: "#1A1C20",
                            }}
                          >
                            {" "}
                            Previous Education :
                          </span>{" "}
                          Undergraduate degree or O/A Levels/Intermediate
                          CGPA/Results.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: " 400",
                              color: "#1A1C20",
                            }}
                          >
                            English Proficiency Tests:
                          </span>{" "}
                          English proficiency test i.e IELTS Academic or TOEFL.
                          Some universities accept IELTS general Training but
                          it’s better to write the IELTS Academic exam to
                          increase your choices.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: " 400",
                              color: "#1A1C20",
                            }}
                          >
                            Resume or CV:
                          </span>{" "}
                          Some Masters programs require Experience of at least
                          10 years to 3 years. Even if you are applying for an
                          undergraduate degree, it’s better to create a CV as it
                          looks professional and make it easier for the
                          recruiter to evaluate your profile.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: " 400",
                              color: "#1A1C20",
                            }}
                          >
                            Statement of Interest:
                          </span>{" "}
                          This is the most important document for your
                          application. The recruiter will mostly base the
                          decision of your application on the quality of
                          Statement of Interest. You must explain your career
                          objectives, and how your previous studies and
                          experience have prepared you for the program.{" "}
                        </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: " 400",
                              color: "#1A1C20",
                            }}
                          >
                            Admission Tests :
                          </span>{" "}
                          SAT/GMAT/GRE or any other admission test if required
                          by your desired University or College
                        </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: " 400",
                              color: "#1A1C20",
                            }}
                          >
                            References:
                          </span>
                          Contact information for two references must be
                          provided. One Professional Recommendation from a
                          supervisor who is familiar with your professional
                          accomplishments (e.g., relevant skills/knowledge,
                          leadership). One Academic Recommendation who knows
                          your academic accomplishments well and can describe
                          them (e.g., a previous professor). If you are applying
                          for an undergraduate degree or your intended degree
                          doesn’t require work experience; then, both the
                          references could be Academic. If you are applying for
                          a Graduate degree and your degree requires work
                          experience; then, both the references could be
                          Professional (As per the discretion of university or
                          college).{" "}
                        </p>
                      </li>

                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: " 400",
                              color: "#1A1C20",
                            }}
                          >
                            Interviews :
                          </span>{" "}
                          Online Interviews are part of the eligibility criteria
                          for most of the Canadian Universities or Colleges. You
                          may have to go through more than one interview as per
                          universities criteria.{" "}
                        </p>
                      </li>
                    </ul>
                    {/* <h3 className="united-states__title3 mb-25">
                      Life Styles
                    </h3>
                    <p className="mb-35">An applicant should apply at center in or closest to, the city in which you are documented as being a resident. Please contact VFS Global if you have any doubts. This crossword explores the various paths humankind can follow to create a more sustainable world. Find out how many ways we can change our approach to the environment
                    </p>
                    <h3 className="united-states__title3 mb-20">
                      Law &amp; Rules
                    </h3>
                    <p className="mb-35">The United States of America, commonly known as the United States or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, 326 Indian reservations, and some minor possessions. At 3.8 million square miles, it is the world's third- or fourth-largest country by total area.
                    </p> */}
                    <div className="beautiful-cities mb-10 mt-15">
                      <div className="row">
                        <div className="col-xxl-10 col-xl-10 col-lg-10">
                          <div className="beautiful-cites">
                            <h3 className="united-states__title3 mb-15">
                              COURSES OFFERED :
                            </h3>

                            <div className="beautiful-link mb-30">
                              <ul>
                                <li>
                                  <a className="active" href="#0">
                                    {" "}
                                    BACHELORS{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="#0">MASTERS</a>
                                </li>

                                {/* <li><a href="#0">California, </a></li>
                                <li><a href="#0">Florida, </a></li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4">
                          <div className="beautiful-cites-place">
                            <img
                              src="assets/img/united-states/butifull.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="united-states__title3 mb-20">
                      POPULAR COURSES OFFERED :
                    </h3>
                    <ul>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>IT </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Different Engineering Courses</p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Medicine courses </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Humanities and Arts </p>
                      </li>
                      <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                        <p>Business and Management courses</p>
                      </li>
                    </ul>

                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">
                        WHAT WE OFFER:
                      </h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>CAREER COUNSEILING. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              GUIDE THE STUDENTS IN CHOOSING THE RIGHT COURSE
                              AND UNIVERSITY.{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>ADMISSION ASSISTANCE. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>ASSISTANCE WITH STUDENT PERMIT. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>PR ASSISTANCE. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>LODGING ASSISTANCE. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>PREPARE FOR IELTS AND OTHER COURSES. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              ESSAY WRITING TRAVELKOOK IS A ONE STOP SOLUTION
                              FOR ALL INTERNATIONAL STUDENTS{" "}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="historical-places mt-20">
                      <h3 className="united-states__title3 mb-20">FAQS :</h3>

                      <div className="historical-places__thumb d-flex align-items-center">
                        <ul>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Tuition Fees for Undergraduate programs varies
                              between CAD 20,000- CAD 27,000 per year.{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>
                              Tuition Fees for Postgraduate programs varies
                              between CAD 30,000 – CAD 80,000 per year.{" "}
                            </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Scholarship Options. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Wide Range Courses. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Career Counselling. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>University Screening. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Part Time Working Facilities for Students. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>PR. </p>
                          </li>
                          <li style={{ listStyle: "disc", marginLeft: "15px" }}>
                            <p>Landing Support in Canada.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area start */}
          <div
            className="abbrand-area pb-120 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="container">
              <div className="abbrand-active owl-carousel">
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/1.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/2.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/3.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/5.png" alt="" />
                  </a>
                </div>
                <div className="abbrand-img">
                  <a href="partners.html">
                    <img src="assets/img/testimonial/4.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
    </main>
  );
};

export default Main;
