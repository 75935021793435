import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import Accordion from './Accordion';

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    return (
        <div className="faq-tab ">
        <Nav tabs className="nav-pills border-bottom-0 pb-50">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' },'faq-tabs-btn')}
              onClick={() => { this.toggle('1'); }}
            >
      Visa & Immigration
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' },'faq-tabs-btn')}
              onClick={() => { this.toggle('2'); }}
            >
      Coaching & Training
            </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' },'faq-tabs-btn')}
              onClick={() => { this.toggle('3'); }}
            >
   Services & Support
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
              <Accordion/>
        
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
            <accordion/>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}